import React, { useEffect } from 'react';
import { Field, FieldArray } from 'formik';

import { BngField } from 'components/bng/form/BngField';
import { BngSelectSearch } from 'components/bng/form/BngSelectSearch';
import Button from 'components/ui/Button';
import { bngYup } from 'components/bng/form/yup/BngYup';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import { BngDoubleField, BngMaskedField, Mask } from 'components/bng/form/BngMaskedField';
import BngInputSpinner from 'components/bng/form/BngInputSpinner';
import MemberFilterList from 'components/bng/pages/newAnalysis/paramTypes/MemberFilterList';
import DimensionListWithFilters from 'components/bng/pages/newAnalysis/paramTypes/DimensionListWithFilters';
import ChartType from 'components/bng/pages/newAnalysis/paramTypes/ChartType';
import ColorParam from 'components/bng/pages/newAnalysis/paramTypes/ColorParam';
import StringParam from 'components/bng/pages/newAnalysis/paramTypes/StringParam';
import FolderParam from 'components/bng/pages/newAnalysis/paramTypes/FolderParam';
import KpiImage from 'components/bng/pages/newAnalysis/paramTypes/KpiImage';
import BooleanParam from 'components/bng/pages/newAnalysis/paramTypes/BooleanParam';
import Radio from 'components/bng/pages/newAnalysis/paramTypes/Radio';
import KpiBandConfig from 'components/bng/pages/newAnalysis/paramTypes/KpiBandConfig';
import SelectOption from 'components/bng/pages/newAnalysis/paramTypes/SelectOption';
import ColorMapperParam from 'components/bng/pages/newAnalysis/paramTypes/ColorMapperParam';
import DimensionMembersList from 'components/bng/pages/newAnalysis/paramTypes/DimensionMembersList';

const ParamType = {
  Measure: {
    processFormData({ formData, param, sourceFields, context }) {
      formData = _.cloneDeep(formData);
      const measure = formData.params[param.name];
      let field = sourceFields.find((sf) => sf.value === measure);

      // If field is not found, search on calculatedMeasures for a match
      if (!field) {
        field = param.props.calculatedMeasures?.find((cm) => cm.value === measure);
        if (field) {
          field = {
            ...field,
            label: context.msg.t(field.label),
          };
        }
      }

      formData.params[`${param.name}Caption`] = field?.label ?? '';
      return formData;
    },
    render({
      name,
      label,
      sourceFields,
      props = { selectFirstMeasureOnInit: false, calculatedMeasures: [], hint: '', disableIfSelected: [] },
      formikProps,
      context,
    }) {
      let options = sourceFields.filter((field) => {
        if (!field.visible) return false;
        return field.type === 'Measure';
      });

      if (!_.isEmpty(props.disableIfSelected)) {
        options = options.map((opt) => {
          const disabled = props.disableIfSelected.some(
            (paramName) => _.get(formikProps.values, `params.${paramName}`, '') === opt.value
          );
          return {
            ...opt,
            disabled,
          };
        });
      }

      useEffect(() => {
        if (props.selectFirstMeasureOnInit) {
          if (_.isEmpty(_.get(formikProps.values, name)) && !_.isEmpty(options)) {
            formikProps.setFieldValue(name, options[0].value);
          }
        }
      }, []);

      if (props.calculatedMeasures) {
        for (const calculatedMeasure of props.calculatedMeasures) {
          options.unshift({
            value: calculatedMeasure.value,
            label: context.msg.t(calculatedMeasure.label),
            type: calculatedMeasure.type ?? 'Measure',
            visible: calculatedMeasure.visible ?? true,
          });
        }
      }

      return (
        <Field
          name={name}
          label={label}
          component={BngField}
          inputComponent={BngSelectSearch}
          options={options}
          rootClassName={'ParamType Measure'}
          rootProps={{ 'data-test': 'Measure' }}
          hint={props.hint ? context.msg.t(props.hint) : undefined}
        />
      );
    },
  },
  TimeDimension: {
    render({ name, label, sourceFields }) {
      const options = sourceFields.filter((field) => {
        if (!field.visible) return false;

        return field.type === 'TimeDimension';
      });
      return (
        <Field
          name={name}
          label={label}
          component={BngField}
          inputComponent={BngSelectSearch}
          options={options}
          rootClassName={'ParamType TimeDimension'}
          rootProps={{ 'data-test': 'TimeDimension' }}
        />
      );
    },
  },
  TimeDimensionWithHierarchy: {
    validationSchema: bngYup((yup) =>
      yup.object({
        dimension: yup.string().required(),
        hierarchy: yup.string().required(),
      })
    ),
    defaultVal(defaultValue = '{}') {
      return Object.assign(
        {},
        {
          dimension: '',
          hierarchy: '',
        },
        defaultValue ? JSON.parse(defaultValue) : {}
      );
    },
    component: ({ name, dimensionOpts = [], hierarchyOpts }) => {
      return (
        <div className="row-fluid ParamType TimeDimensionWithHierarchy" data-test={'TimeDimensionWithHierarchy'}>
          <div className="span6">
            <Field
              name={`${name}.dimension`}
              component={BngField}
              withLabel={false}
              inputComponent={BngSelectSearch}
              options={dimensionOpts}
              rootProps={{
                'data-test': 'TimeDimensionWithHierarchy-DimensionField',
              }}
            />
          </div>
          <div className="span6">
            <Field
              name={`${name}.hierarchy`}
              component={BngField}
              withLabel={false}
              inputComponent={BngSelectSearch}
              options={hierarchyOpts}
              disabled={_.isEmpty(hierarchyOpts)}
              rootProps={{
                'data-test': 'TimeDimensionWithHierarchy-HierarchyField',
              }}
            />
          </div>
        </div>
      );
    },
    render({ name, label, sourceFields, xmlaSchema, formikProps, context }) {
      const dimensionOpts = sourceFields.filter((field) => {
        if (!field.visible) return false;
        return field.type === 'TimeDimension';
      });

      const { values } = formikProps;
      const selectedDimension = _.get(values, `${name}.dimension`);
      const hierarchyOpts = ParamType.Hierarchy.buildOptions(values.cube, selectedDimension, xmlaSchema, context);

      return (
        <Field
          name={name}
          component={BngField}
          label={`<div class="row-fluid noMinHeight"><div class="span6">${label}</div><div class="span6">${context.msg.t(
            'in.the.hierarchy'
          )}</div></div> `}
          showErrors={false}
          inputComponent={ParamType.TimeDimensionWithHierarchy.component}
          asProps={{
            name,
            dimensionOpts,
            hierarchyOpts,
          }}
        />
      );
    },
  },
  Periodicity: {
    render({ name, label, periodicities }) {
      return (
        <Field
          name={name}
          label={label}
          component={BngField}
          inputComponent={BngSelectSearch}
          options={periodicities}
          groupedOpts={true}
          rootClassName={'ParamType Periodicity'}
          rootProps={{ 'data-test': 'Periodicity' }}
        />
      );
    },
  },
  RegularDimension: {
    render({ name, label, sourceFields, formikProps, props }) {
      const disableIfEquals = props.disableIfInUseIn
        ? _.get(formikProps, `values.params.${props.disableIfInUseIn}`)
        : false;
      let options = sourceFields.filter((field) => field.visible && field.type === 'Regular');
      if (disableIfEquals) {
        options = options.map((opt) => {
          if (opt.value === disableIfEquals) {
            return {
              ...opt,
              disabled: true,
            };
          }

          return opt;
        });
      }
      return (
        <Field
          name={name}
          label={label}
          component={BngField}
          inputComponent={BngSelectSearch}
          options={options}
          rootClassName={'ParamType RegularDimension'}
          rootProps={{ 'data-test': 'RegularDimension' }}
        />
      );
    },
  },
  Integer: {
    render({ name, label }) {
      return (
        <Field
          name={name}
          label={label}
          component={BngField}
          rootClassName={'ParamType Integer'}
          rootProps={{ 'data-test': 'Integer' }}
          inputComponent={BngInputSpinner}
          min={1}
          max={9999}
          step={1}
          allowNegative={false}
        />
      );
    },
  },
  Rank: {
    validationSchema: bngYup((yup) =>
      yup.object({
        count: yup.number().positive().required(),
        dimension: yup.string().required(),
      })
    ),
    defaultVal(defaultValue = '{}') {
      return Object.assign(
        {},
        {
          count: 10,
          dimension: '',
        },
        defaultValue ? JSON.parse(defaultValue) : {}
      );
    },
    component: ({ name, options }) => {
      return (
        <div className="row-fluid ParamType Rank" data-test={'Rank'}>
          <div className="span5">
            <Field
              name={`${name}.count`}
              component={BngField}
              withLabel={false}
              rootProps={{
                'data-test': 'Rank-CountField',
              }}
              inputComponent={BngInputSpinner}
              min={1}
              max={9999}
              step={1}
              allowNegative={false}
            />
          </div>
          <div className="span7">
            <Field
              name={`${name}.dimension`}
              component={BngField}
              inputComponent={BngSelectSearch}
              options={options}
              withLabel={false}
              rootProps={{
                'data-test': 'Rank-DimensionField',
              }}
            />
          </div>
        </div>
      );
    },
    render({ name, label, sourceFields }) {
      const options = sourceFields.filter((field) => {
        if (!field.visible) return false;

        return field.type === 'Regular';
      });
      return (
        <Field
          name={name}
          component={BngField}
          label={label}
          showErrors={false}
          inputComponent={ParamType.Rank.component}
          asProps={{
            name,
            options,
          }}
        />
      );
    },
  },
  MeasureList: {
    defaultVal() {
      return ['', ''];
    },
    render({ context, name, label, sourceFields, formikProps }) {
      const options = sourceFields.filter((field) => {
        if (!field.visible) return false;
        return field.type === 'Measure';
      });
      return (
        <FieldArray name={name}>
          {(arrayHelpers) => {
            const measures = _.get(formikProps.values, name) || [];
            return (
              <div className="MeasureListContainer" data-test="MeasureListContainer">
                {measures.map((val, idx) => {
                  const fieldOptions = options.map((opt) => {
                    opt = { ...opt };
                    opt.disabled = measures.includes(opt.value);
                    if (opt.value === val) {
                      opt.disabled = false;
                    }
                    return opt;
                  });

                  return (
                    <div key={idx} className="MeasureList" data-test="MeasureList">
                      <Field
                        name={`${name}[${idx}]`}
                        label={`${label} ${idx !== 0 ? idx + 1 : ''}`}
                        component={BngField}
                        inputComponent={BngSelectSearch}
                        options={fieldOptions}
                        rootProps={{ 'data-test': 'MeasureList-Measure' }}
                      />
                      <div className="RemoveMeasureButton">
                        <BngIconButton
                          icon="delete"
                          onClick={() => arrayHelpers.remove(idx)}
                          disabled={measures.length === 1}
                          data-test={'MeasureList-RemoveButton'}
                        />
                      </div>
                    </div>
                  );
                })}
                <div className="d-flex">
                  <Button
                    className={'bng-button fix save flex-grow-1 Action AddMeasureButton'}
                    onClick={() => arrayHelpers.push('')}
                    disabled={measures.length >= options.length}
                    data-test="AddMeasureButton"
                  >
                    {context.msg.t('add.measure')}
                  </Button>
                </div>
              </div>
            );
          }}
        </FieldArray>
      );
    },
  },
  HierarchyLevel: {
    render({ name, label, xmlaSchema, formikProps, props, context }) {
      let options = [];
      let disabled = true;
      const selectedDimension = _.get(formikProps, `values.params.${props.dimension}`);
      if (selectedDimension) {
        disabled = false;
        const cubeName = _.get(formikProps, `values.cube`);
        const cube = xmlaSchema.cubes?.[cubeName];
        const dimension = cube?.dimensions?.[selectedDimension];
        const hierarchyName = `${selectedDimension}.${props.hierarchy}`;
        const hierarchy = dimension?.hierarchies?.[hierarchyName];
        let levels = Object.values(hierarchy?.levels ?? {});
        if (hierarchy?.hasAll) {
          levels = levels.slice(1);
        }

        const labelSuffix = props.labelSuffix ? context.msg.t(props.labelSuffix).toLowerCase() : '';
        options = levels.map((level) => {
          // Adjust due to invalid caption generation on
          // br.com.sol7.in_memory.controllers.SchemaGenerator.buildQuarterLevel
          let label = level.caption.includes('.') ? level.name : level.caption;

          if (labelSuffix) {
            label += ` ${labelSuffix}`;
          }

          return {
            value: level.name,
            label,
          };
        });

        if (props.removeLast === 'true') {
          options.pop();
        }

        if (props.levelsAfter) {
          const levelsAfter = props.levelsAfter ? _.get(formikProps, `values.params.${props.levelsAfter}`) : '';
          if (levelsAfter) {
            const idx = options.findIndex((o) => o.value === levelsAfter);
            options = options.splice(idx + 1);
          } else {
            disabled = true;
          }
        }
      }

      return (
        <Field
          name={name}
          label={label}
          component={BngField}
          inputComponent={BngSelectSearch}
          options={options}
          disabled={disabled}
          rootClassName={'ParamType HierarchyLevel'}
          rootProps={{ 'data-test': 'HierarchyLevel' }}
        />
      );
    },
  },
  Hierarchy: {
    buildOptions(cube, selectedDimension, xmlaSchema, context) {
      let options = [];
      if (selectedDimension) {
        const dimension = xmlaSchema?.cubes?.[cube]?.dimensions?.[selectedDimension];
        options = Object.values(dimension?.hierarchies ?? {}).map((hierarchy) => {
          const isDefault = dimension.name === hierarchy.name;
          const idx = hierarchy.caption.lastIndexOf('(');
          return {
            value: hierarchy.uniqueName,
            label: isDefault ? context.msg.t('DEFAULT') : hierarchy.caption.substring(idx),
          };
        });
      }
      return options;
    },
    render({ name, label, xmlaSchema, formikProps, props, context }) {
      const { values } = formikProps;
      const selectedDimension = _.get(values, `params.${props.dimension}`);
      const options = ParamType.Hierarchy.buildOptions(values.cube, selectedDimension, xmlaSchema, context);
      return (
        <Field
          name={name}
          label={label}
          component={BngField}
          inputComponent={BngSelectSearch}
          options={options}
          disabled={!selectedDimension}
          rootClassName={'ParamType Hierarchy'}
          rootProps={{ 'data-test': 'Hierarchy' }}
        />
      );
    },
  },
  Number: {
    render({ name, label }) {
      return (
        <Field
          name={name}
          label={label}
          component={BngField}
          inputComponent={BngDoubleField}
          rootClassName={'ParamType Number'}
          rootProps={{ 'data-test': 'Number' }}
          asNumber
        />
      );
    },
  },
  Percent: {
    render({ name, label }) {
      return (
        <Field
          name={name}
          label={label}
          component={BngField}
          inputComponent={BngMaskedField}
          mask={Mask.MEASURE_PERCENT}
          rootClassName={'ParamType Percent'}
          rootProps={{ 'data-test': 'Percent' }}
          asNumber
        />
      );
    },
  },
  ChartType,
  DimensionListWithFilters,
  MemberFilterList,
  Color: ColorParam,
  String: StringParam,
  Folder: FolderParam,
  KpiImage,
  Boolean: BooleanParam,
  Radio,
  KpiBandConfig,
  SelectOption,
  ColorMapperParam,
  DimensionMembersList,
};

export default ParamType;
