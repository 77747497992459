import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import './AGTable.css';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { ResizeSensor } from 'css-element-queries';

import {
  setFilterModel,
  setHoverRow,
  setRowMetaData,
  setSelectedRow,
  setSortModel,
} from 'components/bng/pages/bigTable/redux/actions';
import ContextEnhancer from 'components/ContextEnhancer';
import DefaultRenderer from 'components/bng/pages/bigTable/DefaultRenderer';
import CustomHeader from 'components/bng/pages/bigTable/CustomHeader';
import Snackbar, { SnackbarVariant } from 'components/ui/common/Snackbar';
import {
  buildCssRules,
  buildHourValueFormatter,
  dateComparator,
  defaultFormatter,
  numberComparator,
  numberFormatter,
  quantile,
  valueGetter,
} from 'components/bng/pages/bigTable/functions';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import HourFilter from 'components/bng/pages/bigTable/customFilters/HourFilter';
import DateSort from 'components/bng/pages/bigTable/sortings/DateSort';
import { RenderTotalizerCell, updateTotalizerValue } from 'components/bng/pages/bigTable/components/totalizer';
import { imageFrameStyles, imageSizes, maxColumnWidthAccumulator } from 'components/bng/pages/bigTable/constants';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';

export { AgGridReact };

let BT_CANVAS;
let BT_CANVAS_CONTEXT;

const getCanvasCtx = () => {
  if (!BT_CANVAS) {
    BT_CANVAS = document.createElement('canvas');
    BT_CANVAS_CONTEXT = BT_CANVAS.getContext('2d');
    BT_CANVAS_CONTEXT.font = getComputedStyle(document.body).fontFamily;
  }
  return BT_CANVAS_CONTEXT;
};

const DEFAULT_PADDING = 17;

const AGTable = (props) => {
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const [{ api: gridApi, columnApi: columnApi }, setAgGridApi] = useState({});
  const [showTable, setShowTable] = useState(true);
  const [rowHeight, setRowHeight] = useState(parseInt(props.gridConfig.rowHeight) || 25);
  const [headerHeight, setHeaderHeight] = useState(
    props.gridConfig.showHeader ? parseInt(props.gridConfig.headerHeight) : 0
  );
  const [updated, setUpdated] = useState(undefined);
  const [cssRules, setCssRules] = useState([]);
  const [paginationPageSize, setPaginationPageSize] = useState(20);
  const [filterModel, setFilterModel] = useState(props.filterModel);
  const [columnsSizes, setColumnSizes] = useState({});

  const [applied, setApplied] = useState({
    columns: false,
    rows: false,
  });

  const [sizeCache, setSizeCache] = useState('');
  const containerRef = useRef(null);

  const pinnedBottomRowData = useMemo(() => {
    if (colDefs.length > 0 && !!colDefs.find((c) => c.totalizerConfig.enabled)) {
      return updateTotalizerValue({
        rowCallback: (callback) => gridApi?.forEachNodeAfterFilter(callback),
        colDefs,
        prependOperator: props.exporting || props.isPresentation,
        msg: props.context.msg,
      });
    }
    return [];
  }, [colDefs, filterModel]);

  useEffect(() => {
    const hasImages = props.columns.find((c) => c.fieldType === 'IMAGE_URL');
    if (hasImages) {
      const paddingMap = {
        SMALL: 5,
        AVG: 10,
        BIG: 15,
        CUSTOM: 20,
      };
      let newRowHeight = props.gridConfig.rowHeight;
      let largestCustomImageHeight = 0;
      props.columns.forEach((c) => {
        if (c.fieldType === 'IMAGE_URL' && c.imageConfig.enabled) {
          const size = c.imageConfig.size ?? 'AVG';
          const padding = paddingMap[size];
          if (c.imageConfig.size === 'CUSTOM') {
            if (c.imageConfig.height > largestCustomImageHeight) {
              largestCustomImageHeight = c.imageConfig.height + padding;
            }
          } else {
            if (imageSizes[size]?.value + padding > newRowHeight) {
              newRowHeight = imageSizes[size].value + padding;
            }
            if (c.imageConfig.border) {
              newRowHeight += c.imageConfig.borderWidth;
            }
          }
        }
      });
      newRowHeight = largestCustomImageHeight > newRowHeight ? largestCustomImageHeight : newRowHeight;
      setRowHeight(newRowHeight);
    } else {
      setRowHeight(parseInt(props.gridConfig.rowHeight) || 25);
    }
  }, [props.gridConfig.rowHeight, props.columns]);

  useEffect(() => {
    setHeaderHeight(props.gridConfig.showHeader ? parseInt(props.gridConfig.headerHeight) : 0);
  }, [props.gridConfig.showHeader, props.gridConfig.headerHeight]);

  useEffect(() => {
    if (!containerRef.current) return;

    const sensor = new ResizeSensor(containerRef.current, (e) => {
      setSizeCache(`${e.width}x${e.height}`);
    });

    return () => sensor.detach();
  }, [containerRef.current]);
  useEffect(() => {
    if (updated && props?.onNoData) {
      props.onNoData(!updated?.api.getRowNode(0) && props.location === 'renderablePreload');
    }
  }, [updated]);
  useEffect(() => {
    const currentBodyHeight = gridApi?.gridPanel?.bodyHeight;
    if (currentBodyHeight !== undefined) {
      setPaginationPageSize(Math.floor(currentBodyHeight / rowHeight));
    }
  }, [gridApi?.gridPanel?.bodyHeight, rowHeight]);
  const getRowStyle = (params) => {
    let colDef = params.columnApi.columnModel.columnDefs[0];
    if (!colDef) {
      return {};
    }

    let styles = {};
    let gridConfig = colDef.custom.gridConfig;

    styles.borderBottom = 'none';
    styles.borderTop = 'none';

    if (gridConfig.borderOptions === 'all' || gridConfig.borderOptions === 'horizontal') {
      styles.borderBottom = `${gridConfig.borderWidth}px solid ${gridConfig.borderColor}`;
    }

    return styles;
  };

  const updateSettings = () => {
    setCssRules(buildCssRules(props));
    updatePagination();
    if (gridApi) {
      gridApi.redrawRows();
    }
  };

  const processCol = (dataCol, configCols, parent) => {
    if (dataCol.children) {
      let children = [];
      for (let c = 0; c < dataCol.children.length; c++) {
        let processedChild = processCol(dataCol.children[c], configCols, dataCol);
        if (processedChild) {
          children.push(processedChild);
        }
      }
      return {
        headerName: dataCol.headerName,
        field: dataCol.field,
        children: children,
      };
    } else {
      let col = configCols.find((c) => `[${c.key}]` === dataCol.field || dataCol.field.indexOf(`[${c.key}.(`) >= 0);
      if (!col) {
        return;
      }

      let formatter;
      const isTimeFormat = col.format === 'time';
      const isNumericField = col.fieldType === 'NUMERIC';
      const isImageField = col.fieldType === 'IMAGE_URL';
      const isAccountingFormat = col.format.startsWith('Accounting');

      if (
        isAccountingFormat ||
        col.format === 'number' ||
        col.format === 'percent' ||
        col.format === 'currency' ||
        col.format === 'fixed' ||
        col.format === 'Accounting' ||
        (isTimeFormat && col.fieldType !== 'DATE') ||
        (isNumericField && col.format === 'text')
      ) {
        formatter = numberFormatter(col.decimals, col.format, col.key);
      } else if ((isNumericField && isTimeFormat) || col.formatString === 'Hour') {
        formatter = buildHourValueFormatter(dataCol);
      } else {
        formatter = defaultFormatter(dataCol.field);
      }

      let key = dataCol.field;
      if (parent) {
        key = parent.field + '_' + key;
      }

      let result = {
        colId: key,
        headerName: col.title,
        field: key,
        sortable: col.sortable,
        filter: props.gridConfig.headerFilters
          ? isNumericField
            ? 'agNumberColumnFilter'
            : isImageField
            ? false
            : 'agTextColumnFilter'
          : false,
        filterParams: {
          buttons: ['reset'],
        },
        width: col.width,
        resizable: !props.sizeToFit,
        wrapHeaderText: !props.gridConfig.wrapHeaderText,
        hide: !col.showColumn,
        suppressMovable: !props.editing,
        valueGetter: valueGetter,
        lockPinned: true,
        valueFormatter: formatter,
        cellStyle: function (params) {
          let gridConfig = params.colDef.custom.gridConfig;
          let styles = {};

          styles.borderRight = 'none';
          styles.borderLeft = 'none';
          styles.borderTop = 'none';
          styles.borderBottom = 'none';
          styles.withSpace = 'normal';

          styles.lineHeight = parseInt(props.gridConfig.rowHeight) + 'px';

          if (gridConfig.borderOptions === 'all' || gridConfig.borderOptions === 'vertical') {
            styles.borderRight = `${gridConfig.borderWidth}px solid ${gridConfig.borderColor}`;
          }

          return styles;
        },
        cellRenderer: 'defaultRenderer',
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              component: RenderTotalizerCell,
            };
          }
          return undefined;
        },
        headerComponentParams: {
          backgroundColor: props.gridConfig.headerBackgroundColor,
          fontColor: props.gridConfig.headerFontColor,
        },
        custom: {
          bigTableId: props.id,
          gridConfig: props.gridConfig,
          textFormat: col.textFormat,
          align: col.align,
          verticalAlign: col.verticalAlign,
          fontSize: col.fontSize,
          suffix: col.suffix,
          prefix: col.prefix,
          prefixSuffixSpacing: col.prefixSuffixSpacing,
          decimals: col.decimals,
          autoHeight: col.autoHeight,
          stringFormat: col.format,
          formatString: isTimeFormat ? 'Hour' : col.format,
          columnType: col.columnType,
          getColumnWidth,
        },
        totalizerConfig: {
          enabled: col.totalizerConfig.enabled,
          operator: col.totalizerConfig.operator,
          fontSize: props.totalizerConfig.fontSize,
        },
        imageConfig: {
          enabled: col.imageConfig.enabled,
          size: col.imageConfig.size,
          height: col.imageConfig.size === 'CUSTOM' ? col.imageConfig.height : imageSizes[col.imageConfig.size].value,
          frameStyle: col.imageConfig.frameStyle,
          borderRadius: imageFrameStyles[col.imageConfig.frameStyle].value,
          border: col.imageConfig.border,
          borderColor: col.imageConfig.borderColor,
          borderWidth: col.imageConfig.borderWidth,
        },
        clickConfig: {
          ...col.clickConfig,
        },
      };

      if (col.indicatorsType === 'none') {
        result.custom = {
          ...result.custom,
          textColorType: col.textColor ? '' : 'automatic',
          textColor: col.textColor || '#000000',
          textColorValueType: 'perc_max',
          textColorRanges: [
            { value: 0, color: '#FFFFFFFF', valueOption: 'number' },
            { value: 100, color: '#FFFFFFFF', valueOption: 'number' },
          ],
          textColorGradients: [
            { value: 0, color: '#FFFFFFFF', valueOption: 'number' },
            { value: 100, color: '#FFFFFFFF', valueOption: 'number' },
          ],
          textAutomaticThreshold: 0.65,

          cellColorType: 'none',
          cellColorValueType: 'perc_max',
          cellColorRanges: [
            { value: 0, color: '#FFFFFFFF', valueOption: 'number' },
            { value: 100, color: '#FFFFFFFF', valueOption: 'number' },
          ],
          cellColorGradients: [
            { value: 0, color: '#FFFFFFFF', valueOption: 'number' },
            { value: 100, color: '#FFFFFFFF', valueOption: 'number' },
          ],

          cellBarType: 'perc_max',
          cellBarFixedValue: 0,
          cellBarMeasureColumn: '',
          cellBarColorValueType: 'perc',
          cellBarColorType: 'fixed',
          cellBarColor: '#269C59',
          cellBarColorRanges: [
            { value: 0, color: '#FFFFFFFF', valueOption: 'number' },
            { value: 100, color: '#FFFFFFFF', valueOption: 'number' },
          ],
          cellBarColorGradients: [
            { value: 0, color: '#FFFFFFFF', valueOption: 'number' },
            { value: 100, color: '#FFFFFFFF', valueOption: 'number' },
          ],
          padding: DEFAULT_PADDING,
        };
      } else {
        result.custom = {
          ...result.custom,

          textColorType: col.textColorType,
          textColor: col.textColor,
          textColorValueType: col.textColorValueType,
          textColorRanges: col.textColorRanges,
          textColorGradients: col.textColorGradients,
          textAutomaticThreshold: col.textAutomaticThreshold,

          cellColorType: col.cellColorType,
          cellColorValueType: col.cellColorValueType,
          cellColorRanges: col.cellColorRanges,
          cellColorGradients: col.cellColorGradients,

          cellBarType: col.cellBarType,
          cellBarFixedValue: col.cellBarFixedValue,
          cellBarMeasureColumn: col.cellBarMeasureColumn,
          cellBarColorValueType: col.cellBarColorValueType,
          cellBarColorType: col.cellBarColorType,
          cellBarColor: col.cellBarColor,
          cellBarColorRanges: col.cellBarColorRanges,
          cellBarColorGradients: col.cellBarColorGradients,
          padding: DEFAULT_PADDING,
        };
      }

      if (col.clickConfig.action === 'APPLY_FILTER' && props.onDashboard) {
        result.onCellClicked = async (event) => {
          let members = (props.filters.find((f) => f.id === col.clickConfig.props.filterId)?.members ?? []).slice();
          const newMember = '[' + event.value.id.split('].[').pop();

          const idx = members.indexOf(newMember);
          if (col.clickConfig.props.action === 'CHANGE_FILTER') {
            members = [newMember];
          } else {
            if (idx === -1) {
              members.push(newMember);
            } else {
              members.splice(idx, 1);
            }
          }

          useDashboardPageCtx.getState().filterChangeHandler(
            [
              {
                id: col.clickConfig.props.filterId,
                members,
                restrictionType: 'SHOW_SELECTED',
              },
            ],
            false,
            true
          );
        };
      }

      if (isTimeFormat) {
        result.comparator = dateComparator;
      }

      if (isNumericField) {
        result.comparator = numberComparator;
      }

      if (isNumericField && (isTimeFormat || (col.format === 'text' && col.formatString === 'Hour'))) {
        result.filter = HourFilter;
      }

      if (col.fieldType === 'DATE' || col.columnType === 'TimeDimension') {
        result.comparator = DateSort;
      }

      if (col.pinned !== 'none') {
        result.pinned = col.pinned;
      } else {
        result.pinned = null;
      }

      const column = result.custom;
      if (column.prefixSuffixSpacing) {
        column.prefix = column.prefix === '' ? column.prefix : column.prefix.concat(' ');
        column.suffix = column.suffix === '' ? column.suffix : column.suffix.replace(/^/, ' ');
      } else {
        column.prefix = column.prefix.trim();
        column.suffix = column.suffix.trim();
      }

      return result;
    }
  };

  const updateColumns = (columns, dataColumns) => {
    let orderedDataColumns = [];
    columns.forEach((column) => {
      dataColumns.forEach((dataCol) => {
        if (dataCol.field === `[${column.key}]` || dataCol.field.indexOf(`[${column.key}.(`) >= 0) {
          orderedDataColumns.push(dataCol);
        }
      });
    });

    let computedColDefs = [];
    orderedDataColumns.forEach((dataCol) => {
      let p = processCol(dataCol, columns);
      if (p) {
        if (p.wrapHeaderText) {
          computedColDefs.push(p);
        } else {
          computedColDefs.push({
            ...p,
            wrapHeaderText: false,
            autoHeaderHeight: true,
          });
        }
      }
    });

    if (gridApi) {
      gridApi.setColumnDefs(computedColDefs);
      computedColDefs.forEach((col) => {
        columnApi.setColumnPinned(col.colId, col.pinned);
      });
    }

    setApplied({ ...applied, columns: true });
    setColDefs(computedColDefs);

    if (props.sizeToFit) {
      sizeToFit();
    }

    return computedColDefs;
  };

  const applySortModel = () => {
    if (!columnApi || !_.isArray(props.sortModel)) {
      return;
    }

    const colState = columnApi.getColumnState();
    if (_.isEqual(colState, props.sortModel)) {
      return;
    }

    props.sortModel
      .filter((s) => s !== null)
      .forEach(({ colId, sort }) => {
        const match = colState.find((cs) => cs.colId === colId);
        if (match) {
          match.sort = sort || null;
        }
      });

    columnApi.applyColumnState({
      state: colState,
      applyOrder: true,
    });
  };

  const applyFilterModel = () => {
    if (gridApi && props.filterModel && Object.keys(props.filterModel).length > 0) {
      gridApi.setFilterModel(props.filterModel);
    }
  };

  const maxColumnWidthAcc = useMemo(() => maxColumnWidthAccumulator(), []);

  const getColumnWidth = (text, colId) => {
    const value = getTextWidth(text);
    maxColumnWidthAcc.accumulate(value, colId);
  };

  // Percebemos que a aplicação roda primeiro o onRowsChanged, depois o sizeToFit, então, adicionamos este useEffect para
  // aplicar o valor do cellWidth naquela função do onRowsChanged, para ter o valor da cellWidth no sizeToFit
  useEffect(() => {
    if (!gridApi?.columnModel?.columnDefs || !props.sizeToFit) {
      return;
    }

    const clone = { ...columnsSizes };
    gridApi.columnModel.columnDefs.forEach((colDef) => {
      rowData.forEach((row) => {
        const columnSize = clone[colDef.colId];
        const textWidth = getTextWidth(row[colDef.colId]);
        const width = columnSize?.cellWidth ? columnSize.cellWidth : 0;
        clone[colDef.colId] = {
          ...columnSize,
          cellWidth: Math.max(textWidth, width),
        };
      });
    });
    setColumnSizes(clone);
  }, [gridApi?.columnModel?.columnDefs]);

  const checkImgType = (columnType, formatString, imgConfigSize, height) => {
    if (
      columnType === 'image_url' ||
      columnType === 'url' ||
      columnType === 'Url' ||
      formatString === 'image_url' ||
      formatString === 'url' ||
      formatString === 'Url'
    ) {
      switch (imgConfigSize) {
        case 'SMALL':
          return 80;
        case 'AVG':
          return 110;
        case 'BIG':
          return 140;
        case 'CUSTOM':
          return height + 2;
      }
    }
  };

  // De forma gera aqui foi voltado o código anterior do sizeToFit que tinha antes de fazer o Merge com a Develop e
  // e atualizar para a versão 2.79.0
  const sizeToFit = () => {
    if (!gridApi || !rowData || !columnsSizes || !gridApi.columnModel?.displayedColumns || !containerRef.current) {
      return;
    }

    const screenWidth = containerRef.current.clientWidth;
    const columnSizes = maxColumnWidthAcc.result();
    let sumColumnsSizes = 0;

    gridApi.columnModel.columnDefs.forEach((c) => {
      const temp = checkImgType(c.custom.columnType, c.custom.formatString, c.imageConfig.size, c.imageConfig.height);
      if (temp) {
        sumColumnsSizes += temp;
        columnSizes[c.colId] = temp;
      } else {
        // Aqui a idéia foi pegar o tamanho do conteúdo da célula quando não tiver uma imagem.
        sumColumnsSizes += columnSizes[c.colId] || columnsSizes[c.colId]?.cellWidth;
        columnSizes[c.colId] = columnsSizes[c.colId]?.cellWidth;
      }
    });

    gridApi.columnModel.displayedColumns.forEach((column) => {
      const colId = column.colId;
      if (!(columnsSizes[colId] && !_.isNil(columnsSizes[colId].maxCharSize))) {
        return;
      }
      const columnSize = columnSizes[colId];
      const ratioScreen = screenWidth / sumColumnsSizes;
      const columnSizeRadioScreen = ratioScreen * columnSize;
      if (columnSize !== undefined) {
        column.minWidth = columnSizeRadioScreen;
      }
    });

    gridApi.sizeColumnsToFit();
  };

  useEffect(() => {
    if (gridApi && props.sizeToFit) {
      gridApi.sizeColumnsToFit();
    }
  }, [gridApi?.columnModel?.displayedColumns]);

  useEffect(() => {
    if (
      !props.sizeToFit ||
      !columnApi ||
      !gridApi ||
      !colDefs ||
      Object.values(maxColumnWidthAcc.result()).length === 0
    ) {
      return;
    }
    sizeToFit();
  }, [columnApi, gridApi, colDefs, sizeCache]);

  const updatePagination = () => {
    if (!gridApi || props.gridConfig.pagination !== 'fixed') return;
    gridApi.paginationSetPageSize(props.gridConfig.pageSize);
    gridApi.paginationGoToFirstPage();
  };

  const onGridReady = (params) => {
    params.api.deselectAll();
    setAgGridApi(params);
  };

  useEffect(() => {
    if (!gridApi || !columnApi) return;

    updateColumns(props.columns, props.dataColumns);
    gridApi.setColumnDefs(colDefs);
    updateSettings();

    if (props.sizeToFit) {
      sizeToFit();
    }

    gridApi.addEventListener('columnResized', (ev) => {
      if (!props.onColumnResize || !ev.finished || props.sizeToFit) return;
      const changedCols = ev.columns.map((c) => ({
        colId: c.colId,
        width: c.actualWidth,
      }));
      props.onColumnResize(changedCols);
    });

    if (props?.onSortChanged && columnApi.getColumnState().length > 0) {
      props.onSortChanged(columnApi.getColumnState());
    }
    gridApi.setFilterModel(filterModel);
    props.setFilterModel({ path: props.bigTablePath, filterMod: filterModel });

    gridApi.addEventListener('sortChanged', () => {
      if (!props.onSortChanged) return;
      props.onSortChanged(columnApi.getColumnState());
    });

    if (gridApi && props.selectedRows?.length > 0) {
      gridApi.forEachNode((node) => {
        const isSelected = props.selectedRows.includes(node.rowIndex.toString());
        node.setSelected(isSelected);
      });
    }

    gridApi.addEventListener('filterChanged', (ev) => {
      const tempFilterModel = Object.entries(gridApi.getFilterModel()).reduce((acc, [key, value]) => {
        acc[key] = {
          condition1: null,
          condition2: null,
          operator: null,
          ...value,
        };
        return acc;
      }, {});
      if (props.onFilterChanged) {
        props.onFilterChanged(tempFilterModel);
      }
      setFilterModel(tempFilterModel);
    });

    gridApi.addEventListener('sortChanged', (ev) => {
      if (props?.onSortChanged) props.onSortChanged(columnApi.getColumnState());
    });
  }, [gridApi, columnApi, props.onColumnResize]);

  // Aqui a ideia era implementar este if para determinar um tamanho máximo para as colunas que forem muito grandes,
  // pois na Delta Fire tem uma big table com uma coluna com mais de 240 caracteres e outra cols no máximo 2 caracteres.
  const getTextWidth = (text) => {
    const textMeasure = getCanvasCtx().measureText(text);
    if (textMeasure.width >= 600) {
      return 600;
    }
    return Math.ceil(textMeasure.width + 2 * DEFAULT_PADDING);
  };

  useEffect(() => {
    if (columnApi && !props.location && props?.getColumnApi) {
      props.getColumnApi(columnApi);
    }
  }, [columnApi]);

  const onRowsChanged = () => {
    let metaData = {};
    let columnValues = {};
    const colsMinWidth = {};

    props.columns.forEach((column) => {
      const columnWidth = getTextWidth(column.title) + 36 + (props.gridConfig.headerFilters ? 16 : 0);
      // Aqui a idéia foi usar o mesmo ColumnsSizes e adicionar o tamanho da coluna (Pegar o tamanho do conteúdo da célula, sem o cabeçalho)
      const key = `[${column.key}]`;
      colsMinWidth[key] = {
        ...columnsSizes[key],
        width: columnWidth,
        maxCharSize: column.title.length,
      };
    });

    props.rows.forEach((row) => {
      Object.keys(row).forEach((colKey) => {
        const column = props.columns.find((c) => c.key === colKey.substring(1, colKey.length - 1));
        const rowColValue = row[colKey];
        const rowColValueStr = '' + rowColValue;
        const colMinWidth = colsMinWidth[colKey];

        if (column && rowColValueStr.length > colMinWidth.maxCharSize) {
          const columnWidth = getTextWidth(rowColValue);
          colsMinWidth[colKey] = { width: columnWidth, maxCharSize: rowColValueStr.length };
        }

        if (isNaN(rowColValue) || colKey === 'key') {
          return;
        }

        if (!metaData[colKey]) {
          metaData[colKey] = {
            max: -1 * Number.MAX_SAFE_INTEGER,
            min: Number.MAX_SAFE_INTEGER,
            sum: 0,
            count: 0,
          };
        }

        if (!columnValues[colKey]) {
          columnValues[colKey] = [];
        }
        columnValues[colKey].push(rowColValue);

        if (rowColValue > metaData[colKey].max) {
          metaData[colKey].max = rowColValue;
        }

        if (rowColValue < metaData[colKey].min) {
          metaData[colKey].min = rowColValue;
        }

        metaData[colKey].sum += rowColValue;
        metaData[colKey].count++;
      });
    });

    let result = {};
    Object.keys(metaData).map((m) => {
      result[m] = {
        ...metaData[m],
        average: metaData[m].sum / metaData[m].count,
      };
    });

    Object.keys(columnValues).map((col) => {
      let values = columnValues[col];
      let percentiles = [];
      values.sort((a, b) => a - b);
      for (let i = 0; i <= 100; i++) {
        percentiles.push(quantile(values, i / 100));
      }
      result[col]['percentiles'] = percentiles;
    });

    props.setRowMetaData({
      bigTableId: props.id,
      data: result,
    });

    setRowData(props.rows);
    setColumnSizes(colsMinWidth);
    setApplied({ ...applied, rows: true });

    let totalHeight =
      props.rows.length * parseInt(props.gridConfig.rowHeight) + parseInt(props.gridConfig.headerHeight);
    if (props.onTotalHeightChange) {
      props.onTotalHeightChange(totalHeight);
    }
  };

  useEffect(onRowsChanged, [props.rows]);

  useEffect(() => {
    if (props.readyToDraw) {
      updateColumns(props.columns, props.dataColumns);
      if (gridApi) {
        gridApi.redrawRows();
      }
    }
  }, [props.columns, props.dataColumns, props.gridConfig, props.editing, props.readyToDraw]);

  useEffect(() => {
    updateSettings();
  }, [props.gridConfig]);

  useEffect(() => {
    if (props.reset) {
      gridApi?.deselectAll();
      setShowTable(false);
      setTimeout(() => setShowTable(true), 50);
    }
  }, [props.reset]);

  useEffect(applySortModel, [props.sortModel]);
  useEffect(applyFilterModel, [props.filterModel]);
  useEffect(updatePagination, [props.gridConfig.pageSize]);

  useEffect(() => {
    if (props.sizeToFit) {
      sizeToFit();
    } else if (gridApi) {
      gridApi.columnModel?.displayedColumns.forEach((column) => {
        column.minWidth = 0;
      });
    }
  }, [props.sizeToFit, props.width, props.editing, props.columns, gridApi]);

  useEffect(() => {
    if (applied.columns && applied.rows && gridApi) {
      if (props.onReady && typeof props.onReady === 'function') {
        props.onReady({ gridApi });
      }
    }
  }, [applied, gridApi]);

  const onSelectionChanged = useCallback((event) => {
    const nodes = event.api.getSelectedNodes();
    const selectedNodeId = nodes.length > 0 ? nodes[0].id : null;

    if (isInteractive) {
      if (props.selectedRows && selectedNodeId) {
        const updatedSelectedRows = [...props.selectedRows];
        const idx = updatedSelectedRows.indexOf(selectedNodeId);
        if (idx !== -1) {
          updatedSelectedRows.splice(idx, 1);
        } else {
          updatedSelectedRows.push(selectedNodeId);
        }
        props.onSelectedRowsChanged?.({ selectedRows: updatedSelectedRows });
      }
    } else {
      props.setSelectedRow({
        bigTableId: props.id,
        data: selectedNodeId,
      });
    }
  }, []);

  const getTableKey = () => {
    return props.gridConfig.pagination === undefined
      ? 'table-loading'
      : props.gridConfig.pagination !== 'none'
      ? 'table-paginated'
      : 'table-not-paginated';
  };

  const getTitleDescriptionStyle = (prefix = 'title') => {
    const fontSize = props.titleConfig[`${prefix}FontSize`];
    const format = props.titleConfig[`${prefix}Format`];

    return {
      display: 'block',
      marginBottom: 5,
      fontSize: parseInt(fontSize, 10),
      lineHeight: `${parseInt(fontSize, 10)}px`,
      color: props.titleConfig[`${prefix}Color`],
      textAlign: props.titleConfig[`${prefix}Align`],
      fontWeight: format.includes('bold') ? 'bold' : 400,
      fontStyle: format.includes('italic') ? 'italic' : 'unset',
      textDecoration: format.includes('underline') ? 'underline' : 'unset',
    };
  };

  const getLocalText = useMemo(() => {
    return {
      page: props.context.msg.t('ag-grid.page'),
      to: props.context.msg.t('ag-grid.to'),
      of: props.context.msg.t('ag-grid.of'),

      filterOoo: props.context.msg.t('ag-grid.filterOoo'),
      equals: props.context.msg.t('ag-grid.equals'),
      notEqual: props.context.msg.t('ag-grid.notEqual'),
      empty: props.context.msg.t('ag-grid.empty'),

      // Number Filter
      lessThan: props.context.msg.t('ag-grid.lessThan'),
      greaterThan: props.context.msg.t('ag-grid.greaterThan'),
      lessThanOrEqual: props.context.msg.t('ag-grid.lessThanOrEqual'),
      greaterThanOrEqual: props.context.msg.t('ag-grid.greaterThanOrEqual'),
      inRange: props.context.msg.t('ag-grid.inRange'),
      inRangeStart: props.context.msg.t('ag-grid.inRangeStart'),
      inRangeEnd: props.context.msg.t('ag-grid.inRangeEnd'),

      // Text Filter
      contains: props.context.msg.t('ag-grid.contains'),
      notContains: props.context.msg.t('ag-grid.notContains'),
      startsWith: props.context.msg.t('ag-grid.startsWith'),
      endsWith: props.context.msg.t('ag-grid.endsWith'),

      blank: props.context.msg.t('ag-grid.blank'),
      notBlank: props.context.msg.t('ag-grid.notBlank'),

      // Date Filter
      dateFormatOoo: props.context.msg.t('ag-grid.dateFormatOoo'),

      // Filter Conditions
      andCondition: props.context.msg.t('ag-grid.andCondition'),
      orCondition: props.context.msg.t('ag-grid.orCondition'),

      // Filter Buttons
      applyFilter: props.context.msg.t('ag-grid.applyFilter'),
      resetFilter: props.context.msg.t('ag-grid.resetFilter'),
      clearFilter: props.context.msg.t('ag-grid.clearFilter'),
      cancelFilter: props.context.msg.t('ag-grid.cancelFilter'),
    };
  }, []);

  let style = { width: '100%' };

  if (props.padding) {
    style.width = `calc(100% - ${props.padding}px)`;
  }

  if (!showTable) {
    return <div className="BngBigTable__ag-container" style={style} />;
  }

  const loadingComponent = () => {
    if (updated?.columnApi?.columnModel?.columnDefs?.length === 1) {
      return (
        <Snackbar
          className={'no-data'}
          message={props.context.msg.t('no.data')}
          title={''}
          icon="error_outline"
          variant={SnackbarVariant.Error}
        />
      );
    }

    return (
      <UiBlocker block={true}>
        <div style={{ height: 150, backgroundColor: 'rgba(0,0,0,0)' }} />
      </UiBlocker>
    );
  };

  const paginationOpts = {};
  if (props.onDashboard || !props.exporting) {
    paginationOpts.pagination = props.gridConfig.pagination !== 'none';
    paginationOpts.paginationAutoPageSize = props.gridConfig.pagination === 'auto';
    paginationOpts.paginationPageSize = paginationPageSize;
  }

  const updateSortModel = (newSort) => {
    props.setSortModel({
      path: props.bigTablePath,
      sortModel: newSort,
    });
  };

  const isInteractive = props.columns.some((col) => col.clickConfig.props.hasOwnProperty('filterId'));
  const titleStyle = getTitleDescriptionStyle('title');
  const descriptionStyle = getTitleDescriptionStyle('description');

  return (
    <div className="BngBigTable__ag-container" style={style} ref={containerRef}>
      <style type="text/css" dangerouslySetInnerHTML={{ __html: cssRules.join(' ') }} />

      {props.titleConfig.showTitle && props.titleConfig.title !== '' && (
        <div>
          <span style={titleStyle}>{props.titleConfig.title}</span>
        </div>
      )}

      {props.titleConfig.showDescription && props.titleConfig.description !== '' && (
        <div>
          <span style={descriptionStyle}>{props.titleConfig.description}</span>
        </div>
      )}

      <div
        className={`ag-theme-alpine BngBigTable BngBigTable-${props.id}`}
        style={{
          flex: '1',
        }}
      >
        <AgGridReact
          key={getTableKey()}
          {...paginationOpts}
          rowSelection={isInteractive && props.onDashboard ? 'multiple' : 'single'}
          scrollbarWidth={8}
          suppressMovableColumns={true}
          components={{
            defaultRenderer: DefaultRenderer,
            agColumnHeader: CustomHeader,
          }}
          rowHeight={rowHeight}
          headerHeight={headerHeight}
          noRowsOverlayComponent={loadingComponent}
          getRowStyle={(params) => getRowStyle(params)}
          onSelectionChanged={(e) => onSelectionChanged(e)}
          suppressRowDeselection={false}
          onGridReady={onGridReady}
          onFirstDataRendered={() => {
            updateSettings();
            applyFilterModel();
            applySortModel();
            if (props.sizeToFit) {
              sizeToFit();
            }
          }}
          columnDefs={colDefs}
          rowData={rowData}
          localeText={getLocalText}
          onRowDataChanged={(event) => setUpdated(event)}
          context={props.context}
          suppressColumnVirtualisation={false}
          onFilterChanged={(event) =>
            props.setFilterModel({
              path: props.bigTablePath,
              filterMod: event.api.getFilterModel(),
            })
          }
          onSortChanged={(event) => {
            updateSortModel(event.api.columnModel.sortController.getSortModel());
          }}
          pinnedBottomRowData={pinnedBottomRowData}
        />
      </div>
    </div>
  );
};

export default connect(null, {
  setRowMetaData,
  setSelectedRow,
  setHoverRow,
  setFilterModel,
  setSortModel,
})(ContextEnhancer(AGTable));
