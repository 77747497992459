import { Axios } from 'commonUtils';
import bimEventBus from 'BimEventBus';

class MarketplaceApi {
  billingUrl = 'https://billing.bimachine.com';

  constructor() {
    bimEventBus.on('CeDataInitialized', ({ ceData }) => {
      this.billingUrl = ceData.context?.billingConf?.url ?? this.billingUrl;
    });
  }

  fetchMarketplaceInfo = async (accountId, salesObjId, salesObjType) => {
    return await Axios.getData(`/spr/marketplace/${accountId}`, { params: { salesObjId, salesObjType } });
  };

  activateMarketplaceItem = async ({ accountId, items, plan, vouchers, billingFormat, projectId }) => {
    await Axios.post(`/spr/marketplace/activate`, {
      accountId,
      items,
      billingFormat,
      projectId,
      plan,
      vouchers,
    });
  };

  changeItemStatus = async (salesItemKey, salesItemType, projectId, enable, plan, salesItem) => {
    return await Axios.postData(`/spr/marketplace/addons`, {
      salesItemKey,
      salesItemType,
      projectId,
      enable,
      plan,
      salesItem,
    });
  };

  fetchAll = async (accountId) => {
    return await Axios.getData(`/spr/marketplace/${accountId}/all`);
  };

  marketplaceImageUrl = (ref = '') => {
    if (!ref) {
      return '';
    }
    return Axios.getUri({
      url: `${this.billingUrl}/api/uploads/marketplaceImage`,
      params: {
        ref,
      },
    });
  };

  fetchFeaturesForPlan = async (accountId) => {
    return await Axios.getData(`/spr/marketplace/${accountId}/features/plan`);
  };

  changeAccountPlan = async (accountId, plan, isRequest = true) => {
    return await Axios.postData(`/spr/marketplace/${accountId}/changePlan`, { plan, isRequest });
  };

  fetchVoucher = async (voucherName, accountId) => {
    const data = await Axios.getData(`/spr/marketplace/voucher/${voucherName}`, { params: { accountId } });
    return new Voucher(data);
  };
}

export class Voucher {
  discountType;
  discountValue;
  type;

  constructor(data) {
    Object.assign(this, data);
  }

  applyOnValue = (value) => {
    let result = 0;
    switch (this.type) {
      case 'DISCOUNT': {
        switch (this.discountType) {
          case 'PERCENT': {
            result = value * (this.discountValue / 100);
            break;
          }
          case 'FLAT_VALUE': {
            result = this.discountValue;
            break;
          }
        }
        break;
      }
      case 'BONUS': {
        result = value;
        break;
      }
    }
    result = result * -1;
    return result;
  };
}

export default MarketplaceApi;
