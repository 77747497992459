import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import BlockUi from 'react-block-ui';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import ContextEnhancer from 'components/ContextEnhancer';
import FilterItem from 'components/filter/FilterItem';
import FilterBarWrapper from 'components/filter/FilterBarWrapper';
import { BngDropdown, BngDropdownSeparator } from 'components/bng/ui/BngDropdown';
import BimEventBus from 'BimEventBus';
import { CLEAR_FILTER_EVENT } from 'components/bng/analysis/BngAnalysisDrillDownBar';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import { PUBLISHER_FULL_INTERACTION_EVENT } from 'components/service/bng/PublisherApi';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import UiMsg from 'components/ui/UiMsg';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class FilterBar extends Component {
  static propTypes = {
    filters: PropTypes.array,
    type: PropTypes.oneOf(['DASHBOARD', 'MAP', 'DASHBOARD_ICON', 'REACT_COCKPIT_DASHBOARD']),
    isPublisher: PropTypes.bool,
    canSave: PropTypes.bool,
    onEditorMode: PropTypes.bool,
    filterPosition: PropTypes.object,
    dashboardPath: PropTypes.string,
    dashChanges: PropTypes.array,
    ignoreWrapper: PropTypes.bool,
    isGlobalFilter: PropTypes.bool,
    onLoadingListener: PropTypes.func,
    onChangeListener: PropTypes.func,
    noDropdown: PropTypes.bool,
    beforeItemsSlot: PropTypes.any,
    customFilterSwitch: PropTypes.any,
    renderCustomMember: PropTypes.func,
    alwaysShowFilterBar: PropTypes.bool,
    scrollElId: PropTypes.string,
    onSaveFilters: PropTypes.func,
  };

  static defaultProps = {
    isPublisher: true,
    type: 'DASHBOARD',
    onLoadingListener: _.noop,
    filterPosition: { vertical: 'top', horizontal: 'fixed', retracted: 'expand' },
    ignoreWrapper: false,
    isGlobalFilter: false,
    noDropdown: false,
    onEditorMode: false,
    renderCustomMember: _.noop,
    alwaysShowFilterBar: false,
    scrollElId: 'page-content',
    onSaveFilters: _.noop,
  };

  state = {
    loading: false,
    blockFilters: false,
    filterSelected: { id: 0 },
    showFilterBar: true,
    filterItemsHolder: {},
    currentOverflow: 0,
    contracted: 'expand',
  };

  filterItemRefs = {};

  constructor(props) {
    super(props);
    this.defaultFilters = _.cloneDeep(props.filters);
    this.state.currentOverflow = this.props.filters.length;
  }

  componentDidMount() {
    this._scrollEl = document.getElementById(this.props.scrollElId);
    this.componentDidUpdate(this.props, this.state);
  }

  removeDashScroll = () => {
    this._scrollEl?.classList.add('no-scroll');
  };

  addDashScroll = () => {
    this._scrollEl?.classList.remove('no-scroll');
  };

  defaultValuesForFilter(filter) {
    return _.find(this.defaultFilters, { id: filter.id }).selectedMembers;
  }

  updateFilters = (fn) => {
    fn(this.props.filters);
  };

  clearSelectedFilter = async () => {
    this.setState({ filterSelected: { id: 0 } });
    if (_.isFunction(window.__OBJECT_RELOAD)) {
      window.__OBJECT_RELOAD({ dirty: false, reloadFilters: true });
    }
  };

  handleFilterChange = async (
    filter,
    selectedMembers,
    members = [],
    force = false,
    additionalProps = { clearFilters: false }
  ) => {
    if (selectedMembers.length === 0) {
      this.setState({ currentOverflow: this.state.currentOverflow + 1 });
    }

    filter = _.cloneDeep(filter);
    filter.selectedMembers = selectedMembers;

    BimEventBus.emit(PUBLISHER_FULL_INTERACTION_EVENT, {
      source: 'FilterBar',
    });

    this.props.onLoadingListener(true);
    try {
      this.props.onChangeListener(this.props.filters, force, false, [filter], additionalProps);
    } finally {
      BimEventBus.emit(CLEAR_FILTER_EVENT, {
        filters: [filter],
      });
      this.props.onLoadingListener(false);
    }
  };

  isMap() {
    return this.props.type === 'MAP';
  }

  isDashIcon() {
    return this.props.type === 'DASHBOARD_ICON';
  }

  isReactCockpitDash() {
    return this.props.type === 'REACT_COCKPIT_DASHBOARD';
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.noDropdown) {
      jQuery(this._filterContainer).scrollbar();
    }

    if (prevProps.filters.length !== this.props.filters.length) {
      this.setState({ currentOverflow: this.props.filters.length });
      return;
    }

    this.updateFilterBarState();

    const hasOverflown = this._filterItemsHolder?.offsetWidth < this._filterItemsHolder?.scrollWidth;

    if (this.state.currentOverflow >= 1 && hasOverflown && !this.props.noDropdown) {
      this.setState({ currentOverflow: this.state.currentOverflow - 1 });
    }
  }

  updateFilterBarState = () => {
    const { visibleFilters } = this.findVisibleFilters();
    const isEmpty = _.isEmpty(visibleFilters);
    const $dashWrapper = document.getElementById('dashboard-wrapper');
    if ($dashWrapper) {
      $dashWrapper.classList[isEmpty ? 'remove' : 'add']('dashboard-wrapper-with-filters');
    }
    const showFilterBar = this.props.alwaysShowFilterBar || !isEmpty;
    if (this.props.isPublisher && !showFilterBar) {
      const $filterContainer = document.querySelector('.filter-container.filter-expand');
      if ($filterContainer) {
        $filterContainer.style.display = 'none';
      }

      const $filterBox = document.querySelector('.dashboard-filter-box');
      if ($filterBox) {
        $filterBox.classList.remove('dashboard-filter-box');
      }

      const $dashMarker = document.querySelector('.AllContentWrapper #body-dashboard-home.free-style-marker-class');
      if ($dashMarker) {
        $dashMarker.classList.add('no-filter-bar');
      }

      const $bodyWrapper = document.querySelector('#body-wrapper');
      if ($bodyWrapper) {
        $bodyWrapper.style.paddingTop = 0;
      }
    }

    if (showFilterBar !== this.state.showFilterBar) {
      this.setState({ showFilterBar });
    }
  };

  itemIsFiltered(filter, customFilterMember = null) {
    if (this.isMap()) {
      return !_.isEqual(filter.selectedMembers, this.defaultValuesForFilter(filter));
    } else {
      return customFilterMember || filter.selectedMembers.length !== 0;
    }
  }

  clearFilterItemsCache() {
    window.__CURRENT_FILTER_BAR_CLEAR_ITEM_CACHE__ = false;
    Object.values(this.filterItemRefs).forEach((fi) => {
      try {
        if (fi) {
          fi.wrappedComponent.clearCache();
        }
      } catch (e) {
        console.warn(e);
      }
    });
  }

  onDragEnd = async (result) => {
    try {
      if (!result.destination) return;

      this.setState({ loading: true });
      const sourceId = result.source.droppableId;
      const destinationId = result.destination.droppableId;

      const fromIdx = this.getDragIndex(sourceId, result.source.index, true);
      const toIdx = this.getDragIndex(destinationId, result.destination.index, false, sourceId);
      if (fromIdx === toIdx) {
        this.setState({ loading: false });
        return;
      }

      const items = reorder(this.props.filters.slice(), fromIdx, toIdx);
      await useDashboardPageCtx.getState().addChange({
        type: 'FILTERS',
        data: {
          filters: items.map((item) => item.id),
        },
      });
      this.clearSelectedFilter();
      this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      UiMsg.ajaxError(null, e);
    }
  };

  getDragIndex = (draggableId, index, from, sourceId = null) => {
    switch (draggableId) {
      case 'droppable-visible':
        return index;
      case 'droppable-overflown':
        const sameOrigin = !!sourceId && sourceId === draggableId;
        const needToAdd = from || sameOrigin;
        return this.state.currentOverflow + index + (needToAdd ? +1 : 0);
    }
  };

  openDialog = (methodToExecute) => {
    methodToExecute();
  };

  overflownFilterDropdown = (overflownFilters, filters, rest) => {
    const isOverflownFiltered = overflownFilters.some((filter) => filter.selectedMembers.length > 0);

    return (
      <BngDropdown
        icon="more_horiz"
        className="OverflownFiltersDropdown"
        popperClassName={`OverflownFiltersDropdownPopper OverflownDropdownPosition-${
          this.props.filterPosition.vertical || this.props.filterPosition
        }`}
        onClose={this.addDashScroll}
        keepOpen={true}
        customButton={({ openDropdown }) => {
          return (
            <>
              <BngIconButton
                icon="more_vert"
                onClick={(e) => {
                  this.removeDashScroll();
                  openDropdown(e);
                }}
                className={`OverflownFiltersDropdownButton`}
              />
              {isOverflownFiltered && <div className={`OverflownDropdownFiltered`}></div>}
            </>
          );
        }}
        customOptions={({ closeDropdown }) => {
          return (
            <ul className="OverflownFiltersList">
              <BlockUi tag="div" blocking={this.state.loading}>
                <BngDropdownSeparator title={this.props.context.msg.t('filters.more')} />
                {this.props.onEditorMode && !this.isDashIcon() ? (
                  this.renderDragNDropWrapper(
                    'droppable-overflown',
                    'vertical',
                    overflownFilters,
                    true,
                    (idx, filter, provided) => this.renderFilterItem(idx, filter, provided, rest, filters, true),
                    '.bng-dropdown-parent.OverflownFiltersDropdownPopper'
                  )
                ) : (
                  <>
                    {overflownFilters.map((filter, idx) =>
                      this.renderFilterItem(idx, filter, null, rest, filters, true)
                    )}
                  </>
                )}
              </BlockUi>
            </ul>
          );
        }}
      />
    );
  };

  editableFilterList = (visibleFilters, overflownFilters, filterSelected, filters, rest) => {
    if (this.props.onEditorMode && !this.isDashIcon()) {
      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          {this.renderDragNDropWrapper(
            'droppable-visible',
            'horizontal',
            visibleFilters,
            true,
            (idx, filter, provided) => this.renderFilterItem(idx, filter, provided, rest, filters)
          )}
          {overflownFilters.length > 0 && this.overflownFilterDropdown(overflownFilters, filters, rest)}
        </DragDropContext>
      );
    }

    return (
      <>
        {visibleFilters.map((filter, idx) => {
          const customMember = this.props.renderCustomMember(filter);
          return this.renderFilterItem(idx, filter, null, rest, filters, false, customMember);
        })}
        {overflownFilters.length > 0 && this.overflownFilterDropdown(overflownFilters, filters, rest)}
      </>
    );
  };

  renderDragNDropWrapper = (droppableId, direction, filterArray, placeholder, childRender, parentClassName = null) => {
    return (
      <Droppable droppableId={droppableId} direction={direction}>
        {(provided, snapshot) => {
          if (parentClassName) {
            const $parentEl = document.querySelector(parentClassName);
            if (provided.placeholder) {
              $parentEl.classList.add('growForPlaceholder');
            } else if ($parentEl?.classList.contains('growForPlaceholder')) {
              $parentEl.classList.remove('growForPlaceholder');
            }
          }

          return (
            <div ref={provided.innerRef} style={direction === 'horizontal' ? { display: 'flex' } : {}}>
              {filterArray.map((filter, idx) => {
                return (
                  <Draggable key={filter.id} draggableId={filter.id} index={idx} isDragDisabled={filter.disabled}>
                    {(provided, snapshot) => {
                      return this.optionalPortal(provided.draggableProps.style, childRender(idx, filter, provided));
                    }}
                  </Draggable>
                );
              })}
              {placeholder && provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    );
  };

  optionalPortal = (styles, element) => {
    if (styles.position === 'fixed') {
      return ReactDOM.createPortal(element, this._filterItemsHolder);
    }
    return element;
  };

  renderFilterItem = (idx, filter, provided, rest, filters, onOverflowDropdown = false, customMember) => {
    return (
      <FilterItem
        key={idx}
        filter={filter}
        ref={(ref) => (this.filterItemRefs[filter.id] = ref)}
        onChange={this.handleFilterChange}
        openDialog={this.openDialog}
        customMember={customMember || null}
        filtered={this.itemIsFiltered(filter)}
        selectedFilters={filters}
        isPublisher={this.props.isPublisher || this.isDashIcon()}
        loadingListener={(blockFilters) => {
          this.setState({ blockFilters });
          this.props.onLoadingListener(blockFilters);
        }}
        {...rest}
        provider={provided}
        className="DashboardFilter"
        onOverflowDropdown={onOverflowDropdown}
        showTruncateButton={false}
      />
    );
  };

  findVisibleFilters = () => {
    const overflownFilters = [];
    const visibleFilters = this.props.filters.filter((f, idx) => {
      if (idx > this.state.currentOverflow && !this.props.noDropdown) {
        overflownFilters.push(f);
        return false;
      }

      if (!_.isEmpty(f.selectedMembers) && !f.hide) {
        return true;
      } else if (!f.dashContainFilter && !f.notFromDash) {
        return false;
      }

      return this.props.onEditorMode || this.isDashIcon() || !f.hide;
    });

    return { visibleFilters, overflownFilters };
  };

  render() {
    const { isPublisher, ignoreWrapper, canSave, filters, ...rest } = this.props;
    const { filterSelected, showFilterBar } = this.state;

    if (!showFilterBar) {
      return null;
    }

    const { visibleFilters, overflownFilters } = this.findVisibleFilters();

    let filterPosition = this.props.filterPosition;
    if (application.page.isMobile()) {
      filterPosition = { ...filterPosition };
      filterPosition.horizontal = 'fixed';
      filterPosition.vertical = 'top';
    }

    return (
      <FilterBarWrapper
        isPublisher={isPublisher || this.isDashIcon() || ignoreWrapper}
        filterPosition={filterPosition}
        canSave={canSave && filters.some((f) => !f.disabled)}
        applyScrollbar={this.applyScrollbar}
        onChangeVisibility={(contracted) => this.setState({ contracted: contracted })}
        onSaveFilters={async () => await this.props.onSaveFilters(this.props.filters)}
      >
        <BlockUi tag="div" blocking={this.state.blockFilters || this.state.loading}>
          <div className="row-fluid">
            <div className="filterItemsHolder" ref={(el) => (this._filterItemsHolder = el)}>
              {this.props.beforeItemsSlot}
              {this.editableFilterList(visibleFilters, overflownFilters, filterSelected, filters, rest)}
            </div>
          </div>
        </BlockUi>
      </FilterBarWrapper>
    );
  }
}

export default ContextEnhancer(FilterBar);
