import styles from './AdditionalActivateDialog.module.css';
import ErrorLottie from 'components/bng/securityCheckup/assets/error_lottie.mp4';

import React, { useEffect, useMemo, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useSearchParams } from 'react-router-dom';

import Dialog from 'components/ui/Dialog';
import BngButton, { Type, Variant } from 'components/bng/ui/BngButton';
import useBimContext from 'components/hooks/useBimContext';
import Icon from 'components/ui/common/Icon';
import BngTable from 'components/bng/ui/BngTable';
import { proposalUtils } from 'components/service/bng/AccountApi';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import Utils from 'components/Utils';
import SecurityCheckupDone from 'components/bng/securityCheckup/SecurityCheckupDone';
import useFetchData from 'components/hooks/useFetchData';
import useBimNavigate from 'components/hooks/useBimNavigate';
import { Voucher } from 'components/service/bng/MarketplaceApi';

const buildAdditionalTableCols = ({ context }) => {
  return [
    {
      key: 'quantity',
      label: context.msg.t('quantity'),
      render: (row) => {
        return <div>{row.quantity || 1}</div>;
      },
    },
    {
      key: 'additionalName',
      label: context.msg.t('name'),
      render: (row) => {
        return (
          <div>
            <span>{proposalUtils.translationsForProposalPricing(row).name}</span>
          </div>
        );
      },
    },
    {
      key: 'pricing',
      label: context.msg.t('pricing'),
      render: (row) => {
        return (
          <div className={styles.pricingRow}>
            <span>{`${row.pricing.currency.symbol} ${row.calculatedPricing.toFixed(2)}`}</span>
          </div>
        );
      },
    },
  ];
};

export function ProposalComponent({
  resolvedStatus = null,
  afterResolveCallback = _.noop,
  loadingCallback = _.noop,
  proposalRequest = {},
  detailsAlwaysOpen = false,
  enableAutoResolve = false,
  inDialog = false,
}) {
  const context = useBimContext();
  const [detailsOpen, setDetailsOpen] = useState(detailsAlwaysOpen);
  const [searchParams] = useSearchParams();
  const navigate = useBimNavigate();
  const { data: isMaster } = useFetchData(async () => {
    const account = await Api.Account.findAccount(proposalRequest.buyer.bimAccountId);
    return account.accountMasterId === context.user.id;
  }, []);

  const rows = useMemo(() => {
    const proposalItems = proposalRequest.additionalPricings.slice();
    proposalItems.push(...proposalRequest.servicePricings);
    proposalItems.push(...proposalRequest.planPricings);
    return proposalItems.filter((p) => p.status !== 'DISABLED');
  }, []);

  const resolveProposal = async (accepted) => {
    loadingCallback(true);
    try {
      const status = accepted ? proposalUtils.ACTIVATION_TYPES.ACCEPTED : proposalUtils.ACTIVATION_TYPES.REJECTED;
      await Api.Account.resolveProposal(context.accountId, proposalRequest.id, status, context.project.id);
      UiMsg.ok(context.msg.t(`proposal.${accepted ? 'accept' : 'reject'}.success`));
      afterResolveCallback(status);
    } catch (e) {
      navigate('/errors/500');
      console.error('Error on function acceptProposal()', e);
      UiMsg.error(context.msg.t('accept.request.error'));
    } finally {
      loadingCallback(false);
    }
  };

  useEffect(() => {
    if (!enableAutoResolve || !!resolvedStatus) return;
    (async () => {
      if (searchParams.get('accept') === 'true') {
        await resolveProposal(true);
      } else if (searchParams.get('reject') === 'true' && isMaster) {
        await resolveProposal(false);
      }
    })();
  }, [isMaster]);

  const springStyles = useSpring({
    opacity: detailsOpen ? 1 : 0,
    transform: detailsOpen ? 'translateY(0px)' : 'translateY(-40px)',
    height: detailsOpen ? 100 : 0,
    overflow: 'hidden',
  });

  const appliedVouchers =
    proposalRequest.appliedVouchers?.map((proposalVoucher) => {
      const voucher = new Voucher(proposalVoucher.voucher);

      voucher.itemsApplied = [];
      rows.forEach((row) => {
        if (row.pricing.plan) {
          if (voucher.planPricings.some((pp) => pp.id === row.pricing.id)) {
            const name = proposalUtils.translationsForProposalPricing(row).name;
            const pricing = voucher.applyOnValue(row.pricing.pricing);
            voucher.itemsApplied.push({ name, pricing });
          }
        } else if (row.pricing.additional) {
          if (voucher.additionalPricings.some((ap) => ap.id === row.pricing.id)) {
            const name = proposalUtils.translationsForProposalPricing(row).name;
            const pricing = voucher.applyOnValue(row.pricing.pricing);
            voucher.itemsApplied.push({ name, pricing });
          }
        } else if (row.pricing.service) {
          if (voucher.servicePricings.some((sp) => sp.id === row.pricing.id)) {
            const name = proposalUtils.translationsForProposalPricing(row).name;
            const pricing = voucher.applyOnValue(row.pricing.pricing);
            voucher.itemsApplied.push({ name, pricing });
          }
        }
      });

      return voucher;
    }) ?? [];

  const [subtotal, total, discounts] = useMemo(() => {
    const vouchersValue = appliedVouchers.reduce(
      (currentValue, voucher) =>
        currentValue + voucher.itemsApplied.reduce((currentValue, item) => currentValue + item.pricing, 0),
      0
    );
    const accumulatedAgreedPricing = rows.reduce((acc, pricing) => acc + pricing.agreedPricing, 0);
    const total = Math.max(accumulatedAgreedPricing + vouchersValue, 0);
    const subtotal = rows.reduce((acc, pricing) => acc + pricing.calculatedPricing, 0);
    const discounts = accumulatedAgreedPricing - subtotal;
    return [subtotal, total, discounts];
  }, []);

  const cols = useMemo(() => buildAdditionalTableCols({ context }), []);

  if (resolvedStatus) {
    return (
      <SecurityCheckupDone
        completedSteps={rows.map((row) => proposalUtils.translationsForProposalPricing(row).name)}
        title={
          resolvedStatus === proposalUtils.ACTIVATION_TYPES.REJECTED ? context.msg.t('proposal.refused.title') : null
        }
        description={context.msg.t(
          resolvedStatus === proposalUtils.ACTIVATION_TYPES.REJECTED ? 'proposal.refused.desc' : 'proposal.done.desc'
        )}
        animation={resolvedStatus === proposalUtils.ACTIVATION_TYPES.REJECTED ? ErrorLottie : null}
        showCompletedSteps={false}
      />
    );
  }

  const currencySymbol = proposalRequest.contract.currency.symbol;
  const requestProps = proposalRequest.props.requestProps;

  return (
    <div className="ProposalComponent">
      <div className={`${styles.contentWrapper} ${inDialog ? styles.inDialog : ''}`}>
        <div className={styles.detailsWrapper}>
          {!detailsAlwaysOpen && (
            <a
              onClick={() => {
                setDetailsOpen(!detailsOpen);
              }}
              className={styles.detailsBtn}
            >
              <Icon icon={detailsOpen ? 'arrow_drop_up' : 'arrow_drop_down'} />
              {context.msg.t('see.details')}
            </a>
          )}
          <animated.div className={styles.hiddenDetails} style={springStyles}>
            <b>{context.msg.t('requested.by')}:</b>
            <div className={styles.requestProps}>
              <div>
                <span>{context.msg.t('name')}:</span>
                <b>{requestProps.userName}</b>
              </div>
              <div>
                <span>{context.msg.t('email')}:</span>
                <b>{requestProps.userEmail}</b>
              </div>
              <div>
                <span>{context.msg.t('role')}:</span>
                <b>{requestProps.role}</b>
              </div>
              <div>
                <span>{context.msg.t('date')}:</span>
                <b>{Utils.Date.formatDateTime(requestProps.date)}</b>
              </div>
            </div>
            <b className={styles.idProp}>ID: {proposalRequest.id}</b>
          </animated.div>
        </div>
        <div className={`${styles.tableScrollWrapper}`}>
          <BngTable rows={rows} cols={cols} className={styles.additionalsTable} hideHeader={true} />
        </div>
        {(discounts !== 0 || appliedVouchers.length > 0) && (
          <>
            <div className={styles.subtotalWrapper}>
              <div>
                <span>{context.msg.t('subtotal')}</span>
                <span className={styles.pricingField}>
                  {currencySymbol} {subtotal.toFixed(2)}
                </span>
              </div>
              {discounts !== 0 && (
                <div>
                  <span>{context.msg.t('discounts')}</span>
                  <span className={styles.pricingField}>
                    -{currencySymbol} {discounts.toFixed(2)}
                  </span>
                </div>
              )}
              {appliedVouchers.map((voucher) => {
                return voucher.itemsApplied.map((item) => {
                  const voucherPricing =
                    voucher.type === 'BONUS'
                      ? context.msg.t('bonified.for', [context.msg.t(voucher.duration)])
                      : proposalUtils.getPricingLabel({
                          pricing: item.pricing,
                          currency: voucher.currency ?? proposalRequest.contract.currency,
                        });
                  const isPercent = voucher.type === 'DISCOUNT' && voucher.discountType === 'PERCENT';
                  return (
                    <div>
                      <span>{`${voucher.name} (${isPercent ? `${voucher.discountValue}%` : ''} ${item.name})`}</span>
                      <span className={styles.pricingField}>{voucherPricing}</span>
                    </div>
                  );
                });
              })}
            </div>
          </>
        )}
        <div className={styles.totalWrapper}>
          <span>{context.msg.t('total')}</span>
          <b>
            {currencySymbol} {total.toFixed(2)}
          </b>
        </div>
      </div>
      <div className={styles.buttonWrappers}>
        {isMaster && (
          <BngButton
            className={`${styles.additionalButtons}`}
            onClick={() => resolveProposal(false)}
            variant={Variant.default}
            type={Type.danger}
          >
            {context.msg.t('reject')}
          </BngButton>
        )}
        <BngButton
          className={`${styles.additionalButtons} ${styles.acceptRequestButton}`}
          onClick={() => resolveProposal(true)}
          type={Type.success}
        >
          {context.msg.t('enable')}
        </BngButton>
      </div>
    </div>
  );
}

export default function AdditionalActivateDialog({ closeModal = _.noop, proposalRequest = {} }) {
  const context = useBimContext();
  const [loading, setLoading] = useState(false);
  const [resolved, setResolved] = useState(null);

  return (
    <Dialog
      className={`${styles.AdditionalActivateDialog} AdditionalActivateDialog`}
      onClose={closeModal}
      loading={loading}
      titleComponent={() => {
        if (!!resolved) return;
        return (
          <div className={styles.AdditionalActivateDialogHeader}>
            <Icon icon={'new_releases'} className={styles.headerIcon} />
            <div className={styles.titleWrapper}>
              <span className={styles.activateTitle}>{context.msg.t('attention')}</span>
              <span className={styles.newProposalMsg}>{context.msg.t('new.proposal')}</span>
              <span className={styles.titleSmallPrint}>
                {context.msg.t('this.proposals.expires', [proposalRequest.daysToExpire])}
              </span>
            </div>
          </div>
        );
      }}
    >
      <ProposalComponent
        afterResolveCallback={setResolved}
        resolvedStatus={resolved}
        loadingCallback={setLoading}
        proposalRequest={proposalRequest}
        inDialog={true}
      />
    </Dialog>
  );
}
