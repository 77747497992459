import { Axios } from 'commonUtils';

const endpoint = '/spr/ada/ai';

class AdaAiApi {
  async findAll() {
    return await Axios.getData(endpoint);
  }

  async save(assistant) {
    return await Axios.postData(endpoint, assistant);
  }

  async remove(id = 0) {
    return await Axios.delete(`${endpoint}/${id}`);
  }
}

export default AdaAiApi;
