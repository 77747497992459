import React, { useContext, useState } from 'react';

import Text from 'components/ui/dashboard/components/Text';
import Icon from 'components/ui/common/Icon';
import DashGridContext from 'components/ui/dashboard/components/DashGridContext';
import Api from 'components/Api';
import Utils from 'components/Utils.js';
import FilterService from 'components/filter/FilterService';
import UiMsg from 'components/ui/UiMsg';
import BimEventBus from 'BimEventBus';
import LoadingCenter from 'components/ui/loading/LoadingCenter';
import { CLEAR_FILTER_EVENT } from 'components/bng/analysis/BngAnalysisDrillDownBar';
import useBimContext from 'components/hooks/useBimContext';
import { PUBLISHER_FULL_INTERACTION_EVENT } from 'components/service/bng/PublisherApi';
import UploadApi from 'components/service/bng/UploadApi';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import { renderReactComponent } from 'bng/utils/ReactUtils';
import useId from 'components/hooks/useId';

export const ON_OPEN_OBJECT_EVENT = 'IconText:openObjectAction';

const IconText = ({
                      color,
                      icon,
                      label,
                      labelFont,
                      labelFontSize,
                      labelFontStyle,
                      labelColor,
                      position,
                      autoresize = false,
                      colorbackground = "#ffffff",
                      fontsize = 0,
                      bold = false,
                      action = "NONE",
                      actionProps = {},
                      onClick = null,
                      transparentbackground = false,
                      withoutPermission = false,
                      isMobile = application.page.isMobile(),
                      disableClickEvent = application.utils.isAccessingPublicPublisher(),
                  }) => {
    const context = useBimContext();
    const {dashboardPath, filters, editMode} = useContext(DashGridContext);
    const $currentFilters = useDashboardPageCtx.cached((state) => state?.dash?.gridData?.availableFilters) ?? [];
    const $instanceId = useId();
    const [loading, setLoading] = useState(false);

    const props = {
        label,
        labelFont,
        labelFontSize,
        labelFontStyle,
        labelColor,
        color,
        position,
        autoresize,
        colorbackground,
        fontsize,
        icon,
        bold,
        aling: "center",
        transparentbackground,
    };

    if (!actionProps) {
        actionProps = {};
    }

    let isFiltered = false;

    const iconFilters = actionProps.filters || [];

    const ctxFilters = filters || [];

    if (action === "APPLY_FILTER" && iconFilters.length !== 0 && ctxFilters.length !== 0) {
        isFiltered = iconFilters.every((iconFilter) => {
            const iconMembers = iconFilter.members || [];
            if (iconMembers.length === 0) {
                return false;
            }

            if (!!actionProps.filterIds.find((id) => id === iconFilter.id)) {
                return true;
            }

            return ctxFilters.some((filter) => {
                const isSameId = filter.id === iconFilter.id;
                if (!isSameId) {
                    return false;
                }
                const filterMembers = filter.members || [];
                return iconMembers.every((m) => filterMembers.includes(m));
            })
        })
    }

  const objectFilters =
    actionProps.applyFilters || action === 'APPLY_FILTER'
      ? $currentFilters
          .filter((filter) => {
            return actionProps.filterIds != null && actionProps.filterIds[0] !== -1
              ? actionProps.filterIds.some((id) => id === filter.id)
              : true;
          })
          .map((f) => ({
            id: f.id,
            members: f.selectedMembers.map((sm) => sm.value),
            restrictionType: f.restrictionType,
          }))
      : [];

    const classNames = [
        "IconText",
        isFiltered ? "Highlight" : "",
        !disableClickEvent
            ? action === "NONE"
                ? ""
                : "HoverHighlight"
            : "DISABLE_CLICK",
    ];

    return (
        <Text
            key={`${$instanceId}:${Utils.Strings.hash(props)}`}
            className={classNames.join(" ")}
            onClick={async (e) => {
                if (editMode || disableClickEvent) {
                    return;
                }

                setLoading(true);
                try {
                    if (onClick) {
                        onClick(e);
                        return;
                    }

                    if (withoutPermission) {
                        UiMsg.warn(context.msg.t('dashboard.icon.permission.denied.message'));
                        return;
                    }

                    const handlerFn = ActionHandler[action];
                    if (handlerFn) {
                        const actionParams = {
                            context,
                            actionProps,
                            isMobile,
                            objectFilters,
                            iconFilters,
                            dashboardPath,
                            isFiltered,
                            dashboardFilters: ctxFilters
                        };
                        await handlerFn(actionParams);
                        BimEventBus.emit(PUBLISHER_FULL_INTERACTION_EVENT, {
                            source: 'IconText'
                        });
                    }
                } finally {
                    setLoading(false);
                }
            }}
            loading={loading}
            {...props}
            value={icon?.startsWith?.('/upload')
              ? <img src={UploadApi.previewUrl(icon)} draggable={false}/>
              : <Icon icon={icon} type={Icon.parseType(icon)}/>
            }
        />
    );
};

const openLinkAction = ({actionProps: {link}, isMobile}) => {
    if (!link) return;
    if (isMobile) {
        const message = {
            action: 'link',
            data: link
        };
        Api.ReactNative.postMessage(JSON.stringify(message));
        if (Api.ReactNative.hasWebView(window.parent)) return;
    }
    window.open(link, '_blank');
}

const openObjectAction = async ({
                                    context,
                                    actionProps: {path, applyFilters, filterIds},
                                    isMobile,
                                    objectFilters,
                                    iconFilters,
                                    dashboardFilters = [],
                                }) => {
    if (!path) {
        return;
    }

    const publisherContent = Utils.History.currentUrlSearchParams().get('publisherContent');
    const accessingPublisher = application.utils.isAccessingPublisher();

    if (isMobile && !accessingPublisher && !publisherContent) {
        const availableOnMobile = await Api.Mobile.availableOnMobile({
            path: path,
        });
        if (!availableOnMobile) {
            Swal.fire(
                context.msg.t("object.not.avaliable.on.mobile.title"),
                context.msg.t("object.not.avaliable.on.mobile.message"),
                "warning"
            );
            return;
        }
    }

    // If filter is on filterIds them must follow dash selection (on objectFilters)
    if(applyFilters && filterIds.length > 0) {
      iconFilters = iconFilters.map(f => {
        if(filterIds.includes(f.id)) {
          return {
            ...f,
            members: []
          };
        }
        return f;
      });
    }

    const mixedFilters = applyFilters ? buildMixedFilters(objectFilters, iconFilters) : [];
    const filterRef = await FilterService.createFilterRef(mixedFilters);
    if (Api.ReactNative.hasWebView()) {
        const message = {
            action: 'openObject',
            data: path,
            forcefilters: applyFilters,
            filterRef,
        };
        Api.ReactNative.postMessage(JSON.stringify(message));
    } else if(accessingPublisher) {
        window.location.href = Api.loadObjectUrl({
            content: path,
            breadcrumb: true,
            filterRef,
        });
    } else {
        BimEventBus.emit(ON_OPEN_OBJECT_EVENT, {
                path,
                dashboardFilters,
                filters: mixedFilters,
                forceFilters: applyFilters,
                filterRef,
            }
        );
    }
}

const applyFilterAction = async ({
                                     iconFilters,
                                     dashboardPath,
                                     isFiltered,
                                     actionProps,
                                     objectFilters
                                 }) => {
    if (_.isEmpty(iconFilters)) {
        return;
    }

    const path = dashboardPath;
    const {validFilters, invalidFilters} = await Api.Dash.validateFilterMembers(path, iconFilters);
    // See: https://www.youtube.com/watch?v=Pa8vqRvq8Ps
    const filtersToApply = buildMixedFilters(objectFilters, validFilters, true);
    const filtersToClear = validFilters.filter(validFilter => !actionProps.filterIds.some((id) => id === validFilter.id));
        if (isFiltered) {
            filtersToClear.forEach(f => f.members = []);
        }
        useDashboardPageCtx.getState().filterChangeHandler(
            filtersToApply,
            false,
            true
        );

        BimEventBus.emit(CLEAR_FILTER_EVENT, {
            filters: filtersToClear,
            fromIcon: true
        });

    const alertMsg = invalidFilters
        .filter(invalidFilter => !invalidFilter.filterWasApplied && invalidFilter.dashboardContainFilter)
        .map(invalidFilter => {
            const filterName = invalidFilter.globalFilter.caption || invalidFilter.globalFilter.name;
            return `
                <li>
                    <b>${filterName}:</b>
                    <small>${invalidFilter.removedMembers.join(', ')}</small>
                </li>
            `;
        }).join('');
    if (alertMsg) {
        UiMsg.warn(
            `${context.msg.t('icon.text.filter.invalid.members')}:`,
            `<ul class="d-t-m">${alertMsg}</ul>`
        );
    }

}

const exportObjectAction = async ({actionProps, isMobile, iconFilters = [], dashboardFilters = []}) => {
    if (!actionProps.path) {
        return;
    }

    // Ciço: mantive o filters aqui pois não sei se esta sendo utilizado no mobile, caso não esteja é possível remover
    // e manter somente o filterRef
    const mixedFilters = buildMixedFilters(dashboardFilters, iconFilters, true);
    const filters = FilterService.createFilterParam(mixedFilters);
    const filterRef = actionProps.applyFilters
      ? await FilterService.createFilterRef(mixedFilters)
      : undefined;

    const isAnalysisPath = Utils.Object.isAnalysis(actionProps.path);
    const isBigTablePath = Utils.Object.isBigTable(actionProps.path);

    let mdxRef;

    if (isAnalysisPath && actionProps.applyFilters) {
        mdxRef = await Api.Dash.findItemMdxRef(actionProps.path);
    }

    if (actionProps.exportType === "PDF" || (!isAnalysisPath && !isBigTablePath)) {
        try {
            const exportUrl = Api.Export.exportObjectUrl({
                content: actionProps.path,
                filterRef,
                mdxRef
            });

            if (isMobile) {
                const message = {
                    action: "download",
                    content: actionProps.path,
                    filtro: filters,
                    data: exportUrl,
                    filterRef,
                };
                Api.ReactNative.postMessage(JSON.stringify(message));
                if (Api.ReactNative.hasWebView(window.parent)) {
                    return;
                }
            }
            window.open(exportUrl, "_blank");
        } catch (e) {
            console.error('Error on exportObjectAction()', e);
        }
    } else if (isAnalysisPath && actionProps.exportType === "EXCEL") {
        if (isMobile) {
            const mobileExportUrl = Api.Export.getMobileUrlExcelExport(actionProps.path, {
                filterRef,
                mdxRef
            });

            const message = JSON.stringify({
                action: "download",
                data: mobileExportUrl,
            });
            if (Api.ReactNative.hasWebView()) {
                Api.ReactNative.postMessage(message);
            } else {
                window.postMessage(message, "*");
                window.open(mobileExportUrl, "_blank");
            }
        } else {
            const link = application.utils.isAccessingPublisher()
                ? Api.Export.getMobileUrlExcelExport(actionProps.path, {
                    filterRef,
                    mdxRef
                })
                : await Api.Bng.getExportExcelLink(
                    actionProps.path,
                    {
                        filterRef,
                        iconExport: true,
                        mdxRef
                    }
                );
            window.open(link, "_blank");
        }
    } else if (isBigTablePath && actionProps.exportType === "CSV") {
        try {
            if (isMobile) {

                const filter = filters.filter
                const exportURL = Api.BigTable.generateExportToCsvLink(actionProps.path, {filter});

                const message = JSON.stringify({
                    action: "download",
                    data: exportURL,
                });

                Api.ReactNative.postMessage(message);
            } else {
                await Api.BigTable.exportCsvFromDash(actionProps.path, mixedFilters);
            }
        } catch (e) {
            console.error("Error exporting to excel: ", e);
            UiMsg.ajaxError(null, e);
        }
    }
}

const buildMixedFilters = (dashFilters = [],
                           iconFilters = [],
                           prioritizeDash = false) => {
    const filters = iconFilters.filter((filter) => {
        return filter.members.length > 0
          && (prioritizeDash ? !dashFilters.some((dashFilter) => dashFilter.id === filter.id) : true);
    });
    return dashFilters.concat(filters);
}

const ActionHandler = {
    OPEN_LINK: openLinkAction,
    OPEN_OBJECT: openObjectAction,
    APPLY_FILTER: applyFilterAction,
    EXPORT_OBJECT: exportObjectAction,
}

export default IconText;
