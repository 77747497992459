import React from 'react';
import MktDefaultTab from 'components/bng/pages/marketplace/tabs/MktDefaultTab';
import { GROUP_KEYS } from 'components/bng/pages/marketplace/MarketplaceLayout';

const EDUCATIONAL_GROUPS = [
  {
    title: 'marketplaceTab.educational',
    desc: 'educational.desc',
    key: GROUP_KEYS.EDUCATIONAL,
  },
];

export default function MktEducationalTab({ ...props }) {
  return <MktDefaultTab className={'MktAddonsTab'} groupsToRender={EDUCATIONAL_GROUPS} {...props} />;
}
