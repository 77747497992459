import React, { useMemo, useState } from 'react';
import ObjectRightMenu from 'components/ui/right-menu/ObjectRightMenu';
import useBimContext from 'components/hooks/useBimContext';
import BimQueryConfigurationMenuItem from 'components/bng/pages/admin/structures/bimQuery/menuItems/BimQueryConfigurationMenuItem';
import BimQueryConnectionsMenuItem from 'components/bng/pages/admin/structures/bimQuery/menuItems/BimQueryConnectionsMenuItem';
import BimQueryKeyRecyclingHierarchyMenuItem from 'components/bng/pages/admin/structures/bimQuery/menuItems/BimQueryKeyRecyclingHierarchyMenuItem';
import SaveMenuItem from 'components/ui/right-menu/items/SaveMenuItem';
import RemoveMenuItem from 'components/ui/right-menu/items/RemoveMenuItem';
import Api from 'components/Api';
import BngPulsateLoading from 'components/bng/ui/BngPulsateLoading/BngPulsateLoading';
import UiMsg from 'components/ui/UiMsg';
import ConfigurationTab from 'components/bng/pages/admin/structures/bimQuery/menuTabs/ConfigurationTab';
import ConnectionsTab from 'components/bng/pages/admin/structures/bimQuery/menuTabs/ConnectionsTab';
import KeyRecyclingHierarchyTab from 'components/bng/pages/admin/structures/bimQuery/menuTabs/KeyRecyclingHierarchyTab';
import useTranslation from 'components/hooks/useTranslation';

const availableAccordions = (isEditing, saveDisabled) => [
  {
    key: BimQueryConfigurationMenuItem.KEY,
    Component: BimQueryConfigurationMenuItem,
    component: ConfigurationTab,
    icon: 'menu_open',
    disabled: !isEditing,
  },
  {
    key: BimQueryConnectionsMenuItem.KEY,
    Component: BimQueryConnectionsMenuItem,
    component: ConnectionsTab,
    icon: 'cloud_upload',
    disabled: !isEditing,
  },
  {
    key: BimQueryKeyRecyclingHierarchyMenuItem.KEY,
    Component: BimQueryKeyRecyclingHierarchyMenuItem,
    component: KeyRecyclingHierarchyTab,
    icon: 'settings',
    disabled: !isEditing,
  },
  {
    key: 'SaveMenuItem',
    Component: SaveMenuItem,
    fixed: true,
    disabled: saveDisabled,
  },
  {
    key: 'RemoveMenuItem',
    Component: RemoveMenuItem,
    fixed: true,
  },
];

export default function BimQueryRightMenu({
  onFormikRef,
  isEditing = true,
  saveDisabled,
  ...props
}) {
  const context = useBimContext();
  const { t } = useTranslation();

  const [openAccordionKey, setOpenAccordionKey] = useState(BimQueryConfigurationMenuItem.KEY);
  const [loading, setLoading] = useState(false);

  const accordion = useMemo(() => {
    const tempAcc = availableAccordions().find((acc) => acc.key === openAccordionKey);
    if (tempAcc) {
      return {
        component: tempAcc.component,
        key: tempAcc.key,
      };
    }
    return {};
  }, [openAccordionKey]);

  const handleSave = async () => {
    setLoading(true);
    const { values } = props.formikProps;
    try {
      const bodyReq = {
        projectId: context.project.id,
        connectionId: values.connection,
        sql: values.sql,
        structureName: values.structureName,
        displayName: values.displayName,
        structureDescription: values.structureDescription,
        icon: values.structureIcon,
        fullLoads: values.fullLoads,
        structureType: values.structureType,
        groupId: values.structureTag,
        dataRecyclingEnabled: values.dataRecyclingEnabled,
        dataRecyclingFieldName: values.dataRecyclingFieldName,
        dataRecyclingPeriod: values.dataRecyclingPeriod,
        dataRecyclingTyp: values.dataRecyclingTyp,
        fields: values.fieldConfigs,
      };
      await Api.BimQuery.save(bodyReq);
      UiMsg.ok(t('inMemory_successfullyCreated', [values.displayName]));
      setTimeout(() => window.location.replace(`${Api.baseUrl()}/pages/project-selection/dataconfig.iface`), 3000);
    } catch (e) {
      console.error('Error on BimQueryRightMenu.handleSave()', { e });
      UiMsg.ajaxError(null, e);
    }
  };

  return (
    <>
      <ObjectRightMenu
        {...props}
        context={context}
        open={true}
        onFormikRef={onFormikRef}
        save={handleSave}
        options={availableAccordions(isEditing, saveDisabled)}
        showAccordion={accordion}
        setOpenAccordion={setOpenAccordionKey}
      />
      {loading && <BngPulsateLoading />}
    </>
  );
}
