import styles from './KeyRecyclingHierarchyTab.module.css';

import React from 'react';

import AccordionList from 'components/ui/AccordionList';
import Accordion from 'components/ui/Accordion';
import useTranslation from 'components/hooks/useTranslation';
import KeyFieldSelector from 'components/bng/pages/admin/structures/KeyFieldSelector';
import BngSelectSearch from 'components/bng/form/BngSelectSearch';
import BngInput from 'components/bng/form/BngInput';
import BngSwitch from 'components/bng/form/BngSwitch';
import Icon from 'components/ui/common/Icon';
import BngButton from 'components/bng/ui/BngButton';
import BimQueryConnectionsMenuItem from 'components/bng/pages/admin/structures/bimQuery/menuItems/BimQueryConnectionsMenuItem';
import Api from 'components/Api';

const dataRecyclingFieldName = (t) => [
  {
    value: 'data_load_timestamp',
    label: t('data.load.date'),
  },
];

const dataRecyclingType = (t) => [
  {
    value: 'YEAR',
    label: t('year'),
  },
  {
    value: 'MONTH',
    label: t('month'),
  },
  {
    value: 'DAY',
    label: t('day'),
  },
];

export default function KeyRecyclingHierarchyTab({ isEditing, setOpenAccordion, formikProps }) {
  const { t } = useTranslation();

  const { values, setFieldValue } = formikProps;

  return (
    <AccordionList className={`KeyRecyclingHierarchyTab ObjectRightMenuAccordion`}>
      <Accordion title={t('key.field')} startOpen={true}>
        <KeyFieldSelector
          className={`${styles.keyFieldSelector}`}
          availableFields={values.fieldConfigs ?? []}
          onUpdate={(fields = []) => {
            const clonedFields = values.fieldConfigs.slice();
            if (fields.length > 0) {
              clonedFields.forEach((cf) => (cf.key = fields.includes(cf.fieldName)));
            } else {
              clonedFields.forEach((cf) => (cf.key = false));
            }
            setFieldValue('fieldConfigs', clonedFields);
          }}
        />
      </Accordion>
      <Accordion
        className={styles.dataRecyclingAccordion}
        title={
          <div className={styles.dataRecyclingAccordionTitle}>
            <span>{t('data.recycling')}</span>
            <BngSwitch
              verticalTitle={false}
              checked={values.dataRecyclingEnabled}
              onChange={() => setFieldValue('dataRecyclingEnabled', !values.dataRecyclingEnabled)}
            />
          </div>
        }
        disabled={!values.dataRecyclingEnabled}
        startOpen={false}
      >
        <div className={`${styles.dataRecyclingFieldNameWrapper}`}>
          {t('field')}
          <BngSelectSearch
            className={`${styles.dataRecyclingFieldName}`}
            options={dataRecyclingFieldName(t)}
            onChange={(value) => setFieldValue('dataRecyclingFieldName', value)}
            field={{ value: values.dataRecyclingFieldName, onChange: _.noop }}
          />
        </div>
        <div className={`${styles.keepDataFromLastWrapper}`}>
          {t('keep.data.from.last')}
          <div className={`${styles.dataRecyclingPeriodWrapper}`}>
            <BngInput
              className={`${styles.dataRecyclingPeriod}`}
              type="number"
              min={0}
              max={50}
              value={values.dataRecyclingPeriod}
              onChange={(value) => setFieldValue('dataRecyclingPeriod', value.target.value)}
            />
            <BngSelectSearch
              className={`${styles.dataRecyclingType}`}
              options={dataRecyclingType(t)}
              onChange={(value) => setFieldValue('dataRecyclingType', value)}
              field={{ value: values.dataRecyclingType, onChange: _.noop }}
            />
          </div>
        </div>
      </Accordion>
      {/*TODO: adicionar a criação de hierarquia quando for reescrita a dialog de criação*/}
      <Accordion title={t('hierarchies')} disabled={true}>
        <div className={`${styles.hierarchicalDimensionsAddWrapper}`}>
          {t('hierarchical.dimensions')}
          <Icon icon="add" className={`${styles.hierarchicalDimensionsAdd}`} onClick={() => {}} />
        </div>
        <div className={`${styles.hierarchicalListWrapper}`}>
          <div className={`${styles.hierarchicalEmptyList}`}>
            <Icon className={styles.infoIcon} icon="info" title={t('hierarchical.dimensions.hint', [Api.baseUrl()])} />
            <div className={`${styles.noneHierarchicalDimensionsText}`}>{t('none.hierarchical.dimensions')}</div>
            <div className={`${styles.createHierarchicalDimension}`}>{t('create.hierarchical.dimension')}</div>
          </div>
        </div>
      </Accordion>
      {!isEditing && (
        <>
          <BngButton
            className={`${styles.GoBackButton}`}
            onClick={() => setOpenAccordion(BimQueryConnectionsMenuItem.KEY)}
          >
            {t('bim.query.connection.configuration.go.back')}
          </BngButton>
        </>
      )}
    </AccordionList>
  );
}
