import React from 'react';
import { RightMenuItem } from 'components/ui/right-menu/RightMenu';
import ConfigurationTab from 'components/bng/pages/admin/structures/bimQuery/menuTabs/ConfigurationTab';
import useTranslation from 'components/hooks/useTranslation';

export default function BimQueryConfigurationMenuItem({ toggleAccordion = _.noop, ...props }) {
  const { t } = useTranslation();

  return (
    <RightMenuItem
      {...props}
      title={t('bim.query.menu.item.title.connections')}
      icon="cloud_upload"
      className={`BimQueryConfigurationMenuItem`}
      onClick={() => toggleAccordion(ConfigurationTab, BimQueryConfigurationMenuItem.KEY)}
    />
  );
}

BimQueryConfigurationMenuItem.KEY = 'BimQueryConfigurationMenuItem';
