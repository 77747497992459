import './InactiveProjectDialog.css';
import React, { Component } from 'react';
import { Field, wrapForm } from 'components/ui/FormUtils.js';
import Dialog from 'components/ui/Dialog';
import Button from 'components/ui/Button';
import ContextEnhancer from 'components/ContextEnhancer';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { calculateAccountConsumption } from 'components/ui/accounts/tabs/AccFinanceTab';

class InactiveProjectDialog extends Component {
  static propTypes = {};

  static defaultProps = {
    project: {},
  };

  state = {
    projects: [],
    loading: false,
    open: false,
    isScheduledForDelete: false,
  };

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const projects = await Api.Navbar.projectsMenuDetails();
      const isScheduledForDelete = await Api.Project.hasDeleteSchedules(this.props.context.project.id);
      const filtered = this.filterProjects(projects);
      if (filtered.length > 0) {
        this.props.change('selectedProject', filtered[0].id + '');
      }

      this.setState({
        projects,
        isScheduledForDelete,
      });
    } catch (e) {
      console.error(e);
      UiMsg.ajaxError(null, e);
    } finally {
      this.setState({ loading: false });
    }
  }

  save = ({ selectedProject }) => {
    const { projects } = this.state;
    const id = parseInt(selectedProject);
    const p = projects.find((p) => p.id === id);
    if (p) {
      window.location.href = Api.Bng.accessProjectUrl(p.name);
    }
  };

  handleClick = async () => {
    this.setState({ loading: true });
    try {
      const { project, context } = this.props;

      if (context.accountEnabledForBilling && project.projectType === 'Production') {
        const accountInfo = await Api.Account.fetchBillingAccountInfo(this.props.project?.accountId);
        const metrics = calculateAccountConsumption(accountInfo, project);

        if (metrics.limits.userExceeds || metrics.limits.structuresExceeds) {
          UiMsg.error(this.props.context.msg.t('activate.project.metrics.limit.error'));
          return;
        }
      }
      await Api.Project.toggleEnabled(this.props.context.project.id);
      const accessProjectUrl = Api.Bng.accessProjectUrl(this.props.context.project.name);
      window.location.replace(accessProjectUrl);
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  filterProjects(projects) {
    return projects.filter((p) => !p.isExpired && p.id !== this.props.project.id);
  }

  openOptions = (event) => {
    this.setState({
      popperRef: event.currentTarget,
      open: true,
    });
  };

  render() {
    if (!this.props.initialized) return null;
    const { handleSubmit, formValues } = this.props;
    const { projects, loading } = this.state;
    const proj = projects.find((p) => p.id === this.props.project.id);
    const canManage = proj && proj.isMaster;
    const filteredProjects = this.filterProjects(projects);

    return (
      <Dialog open={this.props.open} hideHeader={true}>
        <form onSubmit={handleSubmit(this.save)} className="inactive-project-form">
          <div className="activate-project">
            <p>
              {this.props.context.msg.t(
                this.state.isScheduledForDelete
                  ? 'InactiveProjectDialog.scheduled.delete.title'
                  : 'InactiveProjectDialog.deactivated.title'
              )}
            </p>
            <p>
              {this.props.context.msg.t(
                canManage
                  ? 'InactiveProjectDialog.deactivated.description'
                  : 'InactiveProjectDialog.deactivated.description2',
                this.props.context.project.displayName
              )}
            </p>

            <aside>
              {canManage && (
                <>
                  <div className="btn-fix">
                    <Button type="button" loading={loading} onClick={this.handleClick}>
                      {this.props.context.msg.t('InactiveProjectDialog.deactivated.yes')}
                    </Button>
                  </div>
                  <div className="btn-exit">
                    <Button type="button" onClick={Api.User.logout}>
                      {this.props.context.msg.t('InactiveProjectDialog.deactivated.no')}
                    </Button>
                  </div>
                </>
              )}
            </aside>
            {!canManage && (
              <div className="btn-exit">
                <Button type="button" onClick={Api.User.logout}>
                  {this.props.context.msg.t('InactiveProjectDialog.deactivated.exit')}
                </Button>
              </div>
            )}
          </div>

          {filteredProjects.length > 0 && (
            <div className="btn-fix">
              <hr />
              <div className="select-project">
                <label>{this.props.context.msg.t('go.to.another.project')}</label>
                <Field component="select" name="selectedProject">
                  {filteredProjects.map((p) => {
                    return (
                      <option key={p.id} value={p.id}>
                        {p.displayName || p.name}
                      </option>
                    );
                  })}
                </Field>

                <Button
                  type="submit"
                  loading={loading}
                  style={{ marginLeft: 10 }}
                  disabled={formValues.selectedProject === '0'}
                >
                  {this.props.context.msg.t('project.expired.dialog.open.project')}
                </Button>
              </div>
            </div>
          )}
        </form>
      </Dialog>
    );
  }
}

export default ContextEnhancer(
  wrapForm({
    component: InactiveProjectDialog,
    mapToState: (state) => ({
      initialValues: { selectedProject: '0', selectedProjectType: '0' },
      project: state.context.project,
    }),
  })
);
