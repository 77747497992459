import styles from './OpenAiForm.module.css';

import React from 'react';
import { Field } from 'formik';
import BngField from 'components/bng/form/BngField';
import BngSelect from 'components/bng/form/BngSelect';
import BngTextArea from 'components/bng/form/BngTextArea';
import useTranslation from 'components/hooks/useTranslation';
import bngYup from 'components/bng/form/yup/BngYup';

export const typeOptions = [
  { value: 'code_interpreter', label: 'Code Interpreter' },
  { value: 'file_search', label: 'File Search' },
  { value: 'function', label: 'Function' },
];

export const modelOptions = [
  { value: 'gpt-4o', label: 'GPT-4o' },
  { value: 'gpt-3.5-turbo', label: 'GPT-3.5-Turbo' },
];

export const llmModelOptions = [{ value: 'OpenAI', label: 'OpenAI' }];

const OpenAiFormSchema = bngYup((yup) => {
  return yup.object({
    type: yup
      .string()
      .required()
      .oneOf(typeOptions.map((option) => option.value))
      .default(''),
    model: yup
      .string()
      .required()
      .oneOf(modelOptions.map((options) => options.value))
      .default(''),
    temperature: yup.number().required().min(0).max(1).default(1),
    instruction: yup.string().required().default(''),
  });
});

export default function OpenAiForm() {
  const { t } = useTranslation();

  return (
    <div className={`OpenAiForm ${styles.OpenAiForm}`}>
      <div className="d-flex gap-2">
        <Field
          rootClassName="w-50"
          name="name"
          component={BngField}
          required={true}
          label={t('ada.ai.open.ai.tab.field.name')}
        />
        <Field
          rootClassName="w-50"
          name="model"
          component={BngField}
          inputComponent={BngSelect}
          required={true}
          label={t('ada.ai.open.ai.tab.field.model')}
          options={modelOptions}
        />
      </div>

      <div className="d-flex gap-2 mt-2">
        <Field
          rootClassName="w-50"
          name="description"
          component={BngField}
          required={true}
          label={t('ada.ai.open.ai.tab.field.description')}
        />
        <Field
          rootClassName="w-50"
          name="temperature"
          component={BngField}
          type="number"
          min={0}
          max={1}
          step={0.1}
          required={true}
          label={t('ada.ai.open.ai.tab.field.temperature')}
          onKeyDown={(event) => {
            if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {
              event.preventDefault();
            }
          }}
        />
      </div>

      <div className="d-flex gap-2 mt-2">
        <Field
          rootClassName="w-50"
          name="type"
          component={BngField}
          inputComponent={BngSelect}
          required={true}
          label={t('ada.ai.open.ai.tab.field.type')}
          options={typeOptions}
        />
      </div>

      <Field
        rootClassName="mt-2"
        name="instruction"
        component={BngField}
        inputComponent={BngTextArea}
        required={true}
        label={t('ada.ai.open.ai.tab.field.instruction')}
      />
    </div>
  );
}

export const OpenAiAssistantModel = {
  component: OpenAiForm,
  schema: OpenAiFormSchema,
};
