import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import FilterService from 'components/filter/FilterService';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';

export const OBJECT_TYPES = ['maps', 'newmap', 'dashboard', 'analysis', 'kpi', 'bigtable'];

class ObjectUtils {
  isMap = (path) => this.isSomeType(path, ['maps', 'newmap']);

  isNewMap = (path) => this.isObjectType(path, 'newmap');

  isOldMap = (path) => this.isObjectType(path, 'maps');

  isDashboard = (path) => this.isObjectType(path, 'dashboard');

  isAnalysis = (path) => this.isObjectType(path, 'analysis');

  isKpi = (path) => this.isObjectType(path, 'kpi');

  isBigTable = (path) => this.isObjectType(path, 'bigtable');

  isLink = (path = '') => this.isObjectType(path, 'iface');

  isBngLink = (path = '') => path?.includes('/spr/bng/') ?? false;

  isSomeType = (path, types = []) => {
    for (let type of types) {
      if (this.isObjectType(path, type)) {
        return true;
      }
    }
    return false;
  };

  isValidLink = (path = '') => {
    return this.isObjectLink(path) || this.isLink(path) || this.isBngLink(path);
  };

  isObjectLink = (path = '') => {
    let pathObject = path.substring(0, path.indexOf('&')) || path.split('content=')[1];
    return this.isSomeType(pathObject, OBJECT_TYPES);
  };

  isObjectType = (path = '', type) => {
    return !!path && this.getObjectType(path).startsWith(type);
  };

  getObjectType = (path) => {
    const ps = this.getObjectInPath(path).split('.');
    if (ps.length === 1) {
      return 'folder';
    } else {
      return ps[ps.length - 1];
    }
  };

  getObjectInPath = (path) => {
    const ps = path.split('/');
    return ps[ps.length - 1];
  };

  getObjectIcon = (path = '', modifier = '') => {
    let type = path;
    if (path.includes('/')) {
      type = Utils.Object.getObjectType(path);
    }
    switch (type) {
      case 'analysis':
        if (modifier === 'showChart') {
          return 'bar_chart';
        } else {
          return 'table_chart';
        }
      case 'kpi':
        return 'speed';
      case 'dashboard':
        return 'space_dashboard';
      case 'newmap':
      case 'maps':
        return 'place';
      case 'cockpit':
        return 'explore';
      case 'bigtable':
        return 'icon-list-alt';
      default:
        return null;
    }
  };

  fileName(path) {
    return this.getObjectInPath(path).split('.').shift();
  }

  parentPath = (path) => {
    const ps = path.split('/');
    return ps.slice(0, ps.length - 1).join('/');
  };

  isValidPath(path) {
    if (_.isEmpty(path)) return false;

    const parts = path.split('/');

    return parts.length !== 0;
  }

  openObject = async (path, filters, dashboardFilters) => {
    const opts = {
      content: path,
      breadcrumb: true,
    };

    if (filters) {
      opts.forcefilters = true;
      opts.filterRef = await FilterService.createFilterRef(filters);
    }

    if(!dashboardFilters) {
      dashboardFilters = useDashboardPageCtx.getState().filters;
    }

    if (!_.isEmpty(dashboardFilters)) {
      opts._dsf = await FilterService.createFilterRef(dashboardFilters);
    }

    const loadUrl = Api.buildUrl('/load.iface', opts);
    window.location.replace(loadUrl);
  };

  isFolder(path) {
    if (!this.isValidPath(path)) return false;

    const parts = path.split('/');
    return !parts.pop().includes('.');
  }

  isFile(path) {
    if (!this.isValidPath(path)) return false;

    return !this.isFolder(path);
  }

  isSameType(path, otherPath) {
    return (
      (Utils.Object.isFolder(path) && Utils.Object.isFolder(otherPath)) ||
      (Utils.Object.isFile(path) && Utils.Object.isFile(otherPath))
    );
  }

  getViewType = async (path) => {
    let viewType = null;
    if (Utils.Object.isAnalysis(path)) {
      viewType = { table: false, chart: false };
      try {
        viewType = await Api.Analysis.getViewType(path);
      } catch (e) {
        console.error(e);
        UiMsg.ajaxError('error', e);
      }
    }
    return viewType;
  };

  extractIdFromPath(path = '') {
    if (path.includes('/')) {
      path = path.substring(path.lastIndexOf('/') + 1);
    }

    if (path.includes('.')) {
      path = path.substring(0, path.lastIndexOf('.'));
    }

    return parseInt(path, 10);
  }

  toMB(sizeInBytes, decimals = 2) {
    return (sizeInBytes / (1024 * 1024)).toFixed(decimals);
  }

  toMBString(sizeInBytes) {
    return `${this.toMB(sizeInBytes)}MB`;
  }

  isObjectPath(path = '') {
    return this.isSomeType(path, OBJECT_TYPES);
  }

  parentFolder(path = '') {
    return path.substring(0, path.lastIndexOf('/'));
  }
}

class NotificationColors {
  getNotificationColor = (notification) => {
    let notifyColor = '#02B046';

    if (notification.type === 'BOOK_GENERATION') {
      notifyColor = '#528DE3';
    } else if (notification.type === 'MONITOR' || notification.criticity.type === 'ALERT') {
      notifyColor = '#E59729';
    } else if (!notification.isSuccess || notification.criticity.type === 'CRITICAL') {
      notifyColor = '#CE171F';
    }

    return notifyColor;
  };
}

class DateUtils {
  DATE_TIME_FORMATTERS = Object.freeze({
    'en-US': 'MM/DD/YYYY hh:mm A',
    'pt-BR': 'DD/MM/YYYY HH:mm',
    'es-ES': 'DD/MM/YYYY hh:mm A',
    'pt-PT': 'DD/MM/YYYY HH:mm',
  });

  DATE_FORMATTERS = Object.freeze(
    Object.entries(this.DATE_TIME_FORMATTERS).reduce((acc, [key, value]) => {
      acc[key] = value.split(' ')[0];
      return acc;
    }, {})
  );

  findDateFormat(locale = window.__USER_LANG) {
    locale = this.normalizeLocale(locale);
    return this.DATE_FORMATTERS[locale] || this.DATE_FORMATTERS['pt-BR'];
  }

  findDateTimeFormat(locale = window.__USER_LANG) {
    locale = this.normalizeLocale(locale);
    return this.DATE_TIME_FORMATTERS[locale] ?? this.DATE_TIME_FORMATTERS['pt-BR'];
  }

  formatDate(date, locale = window.__USER_LANG) {
    let formattedValue = '';
    date = this.toMoment(date);
    if (date) {
      const format = this.findDateFormat(locale);
      formattedValue = date.format(format);
    }
    return formattedValue;
  }

  formatDateTime(date, locale = window.__USER_LANG) {
    let formattedValue = '';
    date = this.toMoment(date);
    if (date) {
      const format = this.findDateTimeFormat(locale);
      formattedValue = date.format(format);
    }
    return formattedValue;
  }

  htmlFormatDate(date) {
    let formattedValue = '';
    date = this.toMoment(date);
    if (date) {
      const format = 'YYYY-MM-DD';
      formattedValue = date.format(format);
    }
    return formattedValue;
  }

  toMoment(date) {
    if (date) {
      if (!moment.isMoment(date)) {
        date = moment(date);
      }
      if (date.isValid()) {
        return date;
      }
    }
    return null;
  }

  normalizeLocale(locale = window.__USER_LANG) {
    if (!locale) {
      locale = 'pt-BR';
    }
    return locale.replace('_', '-');
  }

  timeStringToDate(time = '') {
    if (!time || !time.includes(':')) {
      return null;
    }

    const date = new Date();
    const [hours, minutes] = time.split(':');
    date.setHours(parseInt(hours));
    date.setMinutes(parseInt(minutes));

    return date;
  }
}

class ProjectUtils {
  types = {
    Template: {
      badge: 'badge-warning',
      color: '#f89406',
      name: 'Template',
    },
    Production: {
      badge: 'badge-primary',
      color: '#2283c5',
      name: 'Production',
    },
    Homologation: {
      badge: 'badge-grey',
      color: '#a0a0a0',
      name: 'Homologation',
    },
    Demo: {
      badge: 'badge-important',
      color: '#d15b47',
      name: 'Demo',
    },
  };

  roles = {
    Administrator: {
      name: 'Administrator',
      icon: 'security',
    },
    Expert: {
      name: 'Expert',
      icon: 'auto_fix_high',
    },
    Explorer: {
      name: 'Explorer',
      icon: 'saved_search',
    },
    Viewer: {
      name: 'Viewer',
      icon: 'visibility',
    },
  };

  isDemo = (project) => {
    return project && project.projectType === 'Demo';
  };

  getRoles = () => {
    return [this.roles.Administrator, this.roles.Expert, this.roles.Explorer, this.roles.Viewer];
  };

  getRolesWithMaster = () => {
    return [{ name: 'Master', icon: 'star' }, ...this.getRoles()];
  };

  canExecuteSchedulings = (project) => {
    return project && project.projectType === 'Production';
  };

  isBimachineEmail = (email) => {
    return !!email && new RegExp('@sol7|@bimachine').test(email);
  };

  displayName(project) {
    return project?.caption || project?.displayName || project?.name;
  }
}

const COLLATOR = new Intl.Collator(undefined, { sensitivity: 'base' });

class StringUtils {
  compareIgnoreCase(a, b) {
    return COLLATOR.compare(a, b);
  }

  includesIgnoreCase(str1 = '', str2 = '') {
    if (!_.isString(str1)) {
      str1 = '' + str1;
    }

    if (!_.isString(str2)) {
      str2 = '' + str2;
    }
    return str1.toLowerCase().includes(str2.toLowerCase());
  }

  // Ref: https://stackoverflow.com/a/7616484
  hash(val = '') {
    if(!_.isString(val)) {
      val = JSON.stringify(val);
    }

    let hash = 0,
      i,
      chr;
    if (val.length === 0) return hash;
    for (i = 0; i < val.length; i++) {
      chr = val.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }
}

class JsObjectUtils {
  removeEmptyKeys(obj) {
    if (obj) {
      Object.keys(obj).forEach((k) => {
        const val = obj[k];
        if (_.isUndefined(val) || _.isNull(val)) {
          delete obj[k];
        } else if (_.isPlainObject(val)) {
          this.removeEmptyKeys(val);
        }
      });
    }
    return obj;
  }
}

class BigTable {
  returnFilterModelForPath = (path, bigtableFilterModels = []) => {
    for (const item of bigtableFilterModels) {
      if (path === item.path) {
        return item.filterMod;
      }
    }
    return {};
  };
  returnSortModelForPath = (path, bigtableSortModels = []) => {
    for (const item of bigtableSortModels) {
      if (path === item.path) {
        return item.sortModel;
      }
    }
    return [];
  };
}

class HistoryStateHelper {
  EVENT_NAME = 'HistoryStateHelper:pushState';

  currentUrlSearchParams() {
    return new URLSearchParams(window.location.search);
  }

  updateQuery(params = {}, opts = { replace: false, emitEvent: false }) {
    const url = new URL(window.location);

    if (opts?.replace) {
      const keys = Array.from(url.searchParams.keys());
      for (const key of keys) {
        url.searchParams.delete(key);
      }
    }

    for (const [key, value] of Object.entries(params)) {
      if (_.isNull(value)) {
        url.searchParams.delete(key);
      } else {
        url.searchParams.set(key, value);
      }
    }

    window.history.pushState(null, '', url);
    if (opts?.emitEvent) {
      window.dispatchEvent(new Event(this.EVENT_NAME));
    }
  }
}

function isPromise(p) {
  return typeof p === 'object' && typeof p.then === 'function';
}

class CacheUtils {
  memoize(fn, keyFn) {
    const cache = {};

    if (!keyFn) {
      keyFn = (...args) => {
        return args.map((a) => `${a}`).join(':') || 'EMPTY';
      };
    }

    return async (...args) => {
      const key = keyFn.apply(null, args);

      if (cache.hasOwnProperty(key)) {
        const valOrPromise = cache[key];
        if (!valOrPromise) return valOrPromise;

        if (isPromise(valOrPromise)) {
          return new Promise(async (resolve, reject) => {
            try {
              let val = await valOrPromise;
              val = _.cloneDeep(val);
              resolve(val);
            } catch (e) {
              delete cache[key];
              reject(e);
            }
          });
        } else {
          return _.cloneDeep(valOrPromise);
        }
      }

      const valOrPromise = fn.apply(null, args);

      if (isPromise(valOrPromise)) {
        const wrappedPromise = new Promise(async (resolve, reject) => {
          try {
            const val = await valOrPromise;
            cache[key] = _.cloneDeep(val);
            resolve(val);
          } catch (e) {
            delete cache[key];
            reject(e);
          }
        });
        cache[key] = wrappedPromise;
        return wrappedPromise;
      } else {
        cache[key] = _.cloneDeep(valOrPromise);
        return valOrPromise;
      }
    };
  }
}

class ExternalPagesUtils {
  externalPages = [
    '/pages/addon/addons-page.iface',
    '/pages/addon/addon-info.iface',
    '/pages/labs/index.iface',
    '/spr/bng/labs',
    '/spr/bng/store',
    '/spr/bng/university',
    '/bng/marketplace',
    '/bng/accounts',
    '/bng/proposal',
  ];

  isExternalPage() {
    return this.externalPages.some((url) => window.location.pathname.includes(url));
  }
}

class SchedulingUtils {
  DELIVERY_TYPE = {
    EMAIL: 'EMAIL',
    WHATSAPP: 'WHATSAPP',
    TELEGRAM: 'TELEGRAM',
  };

  TYPE = {
    OBJECT: 'OBJECTS',
    COCKPIT: 'COCKPITS',
  };

  SCHEDULING_TYPE = {
    IMMEDIATE: 'Immediate',
    SCHEDULED: 'Scheduled',
  };

  EXPORT_TYPE = {
    PDF: 'PDF',
    PNG: 'PNG',
    EXCEL: 'EXCEL',
    CSV: 'CSV',
  };

  ENDED_WITH = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    RUNNING: 'RUNNING',
    QUOTA_EXCEEDED: 'QUOTA_EXCEEDED',
  };

  IN_MEMORY_ENDED_WITH = {
    ...this.ENDED_WITH,
    NEW_DATA_NOT_FOUND: 'NEW_DATA_NOT_FOUND',
    NOT_FINISHED: 'NOT_FINISHED',
    NOT_STARTED: 'NOT_STARTED',
  };

  LOAD_TYPE = {
    TOTAL: 'Total',
    INCREMENTAL: 'Incremental',
    GEOREFERENCE: 'Georeference',
    DELETE: 'Delete',
  };

  iconForMsgType(messageType) {
    switch (messageType) {
      case 'EMAIL':
        return 'mail';
      case 'WHATSAPP':
        return 'fa-whatsapp';
      case 'TELEGRAM':
        return 'fa-telegram';
      default:
        return null;
    }
  }
}

class UserUtils {
  displayName(user) {
    return user?.displayName || user?.name || user?.email;
  }

  isSystemUser(user) {
    const email = _.isString(user) ? user : user.email;
    return ['desenvolvimento@sol7.com.br', 'suporte@sol7.com.br', 'system@sol7.com.br'].includes(email);
  }

  isConsultant(user) {
    const email = _.isString(user) ? user : user.email;
    return email.endsWith('@sol7.com.br') || email.endsWith('@bimachine.com.br');
  }

  isFinanceUser = (email) => {
    return email && email === 'financeiro@bimachine.com.br';
  };
}

class Utils {
  static Object = new ObjectUtils();
  static Date = new DateUtils();
  static Notification = new NotificationColors();
  static Project = new ProjectUtils();
  static History = new HistoryStateHelper();
  static BigTable = new BigTable();
  static Strings = new StringUtils();
  static ObjectUtils = new JsObjectUtils();
  static Cache = new CacheUtils();
  static ExternalPages = new ExternalPagesUtils();
  static Scheduling = new SchedulingUtils();
  static Users = new UserUtils();
  static Bytes = {
    toMB(sizeInBytes, decimals = 2) {
      const mb = (sizeInBytes / (1024 * 1024)).toFixed(decimals);
      return `${mb}MB`;
    },
  };

  static randomId() {
    return `RID${Math.random().toString(36).substring(2)}`;
  }

  static fileSizeSI(sizeInBytes, fractionDigits = 2) {
    const kb = 1024;
    const e = (Math.log(sizeInBytes) / Math.log(kb)) | 0;
    return (sizeInBytes / Math.pow(kb, e)).toFixed(fractionDigits) + ' ' + (e ? 'KMGTPEZY'[e - 1] + 'B' : 'B');
  }

  static formatBytesToGb(sizeInBytes) {
    return (sizeInBytes / 1024 / 1024 / 1024).toFixed(2);
  }

  static formatMbToGb(sizeInMb) {
    return (sizeInMb / 1024).toFixed(2);
  }

  static copyToClipboard = (() => {
    function fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    }

    function copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        function () {},
        function (err) {
          console.error('Async: Could not copy text: ', err);
        }
      );
    }

    return copyTextToClipboard;
  })();

  static sleep = (time) => new Promise((res) => setTimeout(res, time));

  static parentWindowIsAccessible() {
    try {
      window.parent.document;
      return true;
    } catch (e) {
      return false;
    }
  }
}

window._Utils = Utils;

export default Utils;
