import React, { PureComponent } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import Utils from 'components/Utils';
import ContextEnhancer from 'components/ContextEnhancer';
import { RightMenuItem } from 'components/ui/right-menu/RightMenu';
import { KPI_EDIT_MENU_EVENT } from 'components/bng/pages/kpi/KpiEditMenu';

class ConfigureMenuItem extends PureComponent {

    static propTypes = {
        path: PropType.string,
    };

    static defaultProps = {
        path: '',
    };

    openEditDialog = async (event) => {
        const {path, clearAccordion, context, kpiMutations} = this.props;

        clearAccordion();
        const type = Utils.Object.getObjectType(path).toUpperCase();
        switch (type) {
            case 'MAPS':
                context.execute(`#{mapsCRUD.editMap("${path}")}`);
                break;
            case 'KPI':
                bimEventBus.emit(KPI_EDIT_MENU_EVENT, {path, mutations: kpiMutations, toggle: true});
                break;
            case 'ANALYSIS':
                context.execute(`#{analysisBean.openAnalysisPropertiesDialog()}`);
                break;
            default:
                console.warn(`Configuration Dialog not configure for object of type ${type}`);
        }
    };

    render() {
        const {context, ...props} = this.props;
        return (
            <RightMenuItem {...props}
                                className="ConfigureMenuItem"
                                title={context.msg.t('properties')}
                                icon="settings"
                                onClick={this.openEditDialog}/>
        );
    }
}

export default connect()(ContextEnhancer(ConfigureMenuItem));
